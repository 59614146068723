import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 1080 1080">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >


<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M1080.000000,1081.000000 
	C720.401245,1081.000000 360.802460,1081.000000 1.000000,1081.000000 
	C1.000000,721.635803 1.000000,362.271515 1.453632,2.453392 
	C357.087494,1.999498 712.267761,1.999038 1067.447998,2.004778 
	C1071.610352,2.004845 1075.772827,2.085058 1079.963623,2.564678 
	C1079.994873,362.334320 1079.997437,721.667175 1080.000000,1081.000000 
M590.696106,346.036835 
	C590.986023,345.721344 591.276001,345.405884 591.888428,344.888702 
	C592.012939,344.658966 592.066223,344.413757 592.737915,343.997711 
	C608.921631,330.956879 625.105347,317.916077 641.915771,304.370239 
	C639.667786,315.978760 637.625244,326.526581 635.153748,337.648468 
	C633.855713,344.206146 632.557739,350.763794 631.240417,357.419006 
	C636.716614,357.419006 641.614258,357.419006 646.616760,357.419006 
	C647.379944,353.394897 648.084961,349.677002 649.046997,345.152161 
	C649.160217,343.524902 649.080933,341.856720 649.416870,340.276825 
	C652.267700,326.871063 655.194092,313.481415 658.051025,300.062592 
	C658.051025,300.062592 658.075134,300.108826 658.644592,299.896332 
	C658.737854,298.607269 658.831116,297.318207 659.019409,295.577850 
	C659.019409,295.577850 659.044800,295.117371 659.628784,294.858063 
	C659.919006,293.866119 660.209290,292.874176 660.866821,291.143555 
	C661.120117,289.866241 661.397217,288.593109 661.622803,287.310913 
	C663.001160,279.476410 664.367981,271.639862 665.850098,263.711670 
	C665.850098,263.711670 665.969177,263.914490 665.969177,263.914490 
	C665.969177,263.914490 665.861572,263.730469 665.393005,264.223450 
	C657.379822,270.842285 649.366699,277.461121 640.886719,284.267853 
	C640.815369,284.419678 640.744080,284.571472 640.837280,284.866180 
	C640.837280,284.866180 640.725708,284.678955 640.177246,284.832703 
	C639.801453,285.226379 639.425659,285.620087 638.468506,286.175385 
	C637.932312,286.750244 637.396179,287.325134 636.957397,287.964081 
	C636.957397,287.964081 636.905762,287.859619 636.281677,288.023865 
	C635.170593,289.005157 634.059448,289.986450 632.997498,291.000092 
	C632.997498,291.000092 632.974609,290.945892 632.446228,291.063995 
	C632.255005,291.356445 632.063721,291.648895 631.955444,291.968750 
	C631.955444,291.968750 631.928589,291.885559 631.297058,292.067657 
	C629.826599,293.352173 628.356079,294.636658 626.964539,295.967468 
	C626.964539,295.967468 626.942810,295.878571 626.402161,296.001038 
	C626.201355,296.298096 626.000610,296.595154 625.865723,296.920227 
	C625.865723,296.920227 625.828003,296.859100 625.170227,297.037720 
	C611.283203,308.316681 597.396179,319.595642 583.326355,331.023041 
	C581.450073,327.317444 579.791504,323.886047 577.996704,320.527466 
	C573.415466,311.954803 568.773499,303.414642 564.127258,294.918335 
	C564.127258,294.918335 564.179260,294.882965 564.098389,294.168365 
	C563.084900,292.388672 562.071411,290.608948 561.048645,288.385162 
	C561.048645,288.385162 560.854858,287.985535 560.954773,287.477295 
	C560.852234,287.338348 560.749756,287.199371 560.486206,286.395477 
	C557.429932,280.628052 554.373657,274.860626 551.227051,268.444153 
	C550.267456,266.935760 549.307800,265.427368 548.281067,263.259918 
	C545.680237,257.883026 543.079346,252.506119 539.984497,246.107895 
	C536.607239,252.856003 533.821533,258.422150 530.503113,264.419678 
	C529.067261,267.300415 527.631409,270.181122 525.781616,273.593567 
	C524.810669,275.577362 523.839722,277.561157 522.366333,279.877594 
	C522.275635,280.257568 522.184998,280.637573 522.002014,281.062012 
	C522.002014,281.062012 522.052063,281.151367 521.629456,281.515442 
	C521.420532,282.010101 521.211609,282.504761 521.000000,283.000000 
	C521.000000,283.000000 521.000916,283.004242 520.497864,283.425385 
	C519.989441,284.611084 519.481018,285.796753 519.003723,286.992004 
	C519.003723,286.992004 518.978210,286.967896 518.498230,287.324677 
	C518.297668,287.873962 518.097046,288.423248 517.391968,289.272644 
	C517.219238,289.827667 517.046509,290.382690 516.270142,291.255341 
	C510.136200,302.981934 503.982574,314.698273 497.883453,326.442932 
	C496.518585,329.071136 495.522827,329.663025 492.820740,327.339386 
	C481.719879,317.793335 470.372589,308.530792 459.017059,299.285431 
	C454.168396,295.337769 449.077209,291.687988 444.067352,287.968170 
	C444.067352,287.968170 444.138672,287.965210 443.952881,287.390228 
	C443.670959,287.123535 443.389069,286.856873 442.978485,286.192627 
	C442.768066,285.975342 442.512939,285.886597 441.975677,285.369293 
	C441.623779,285.187866 441.271881,285.006470 440.642242,284.240753 
	C432.611603,277.657562 424.580994,271.074371 416.550354,264.491180 
	C416.234863,264.679108 415.919373,264.867004 415.603882,265.054901 
	C417.744415,274.019501 419.884918,282.984131 421.966492,292.643738 
	C422.058807,293.097321 422.151123,293.550903 422.094269,294.532654 
	C422.309357,295.007233 422.524414,295.481842 422.800842,296.800049 
	C426.330658,312.997864 429.860474,329.195709 433.404205,345.374420 
	C433.404205,345.374420 433.409729,345.395721 433.470551,346.067078 
	C433.701599,346.701935 433.932648,347.336823 434.089111,348.771484 
	C434.789612,351.776428 435.490082,354.781372 436.183990,357.757965 
	C441.297913,357.757965 446.040710,357.757965 451.180023,357.757965 
	C450.299652,353.977600 449.484772,350.478333 448.718903,346.201996 
	C445.922333,332.780365 443.125732,319.358765 440.329132,305.937134 
	C443.711182,307.261139 446.012390,309.079620 448.291443,310.925476 
	C460.532867,320.840088 472.768738,330.761505 485.086304,340.941864 
	C485.086304,340.941864 485.356049,340.898193 485.719696,341.404541 
	C487.391052,342.712555 489.062408,344.020569 490.800354,345.203247 
	C490.800354,345.203247 490.659576,345.221222 490.903961,345.737671 
	C491.247162,345.891357 491.590363,346.045044 492.197235,346.803558 
	C495.763245,349.590515 499.329254,352.377472 502.917023,355.066986 
	C502.917023,355.066986 502.817719,355.077820 503.048004,355.634277 
	C503.389282,355.825134 503.730560,356.015991 504.357330,356.783875 
	C509.231384,360.687195 514.105408,364.590515 519.398987,369.057983 
	C521.035400,370.327148 522.671875,371.596283 524.671265,373.394775 
	C529.290222,377.202148 533.813416,381.137726 538.604126,384.715057 
	C539.633972,385.484070 542.147217,385.637238 543.094604,384.915558 
	C547.970703,381.201538 552.559082,377.109863 557.877747,372.927277 
	C561.619446,369.788818 565.361206,366.650360 569.671143,363.258820 
	C570.097961,362.832062 570.524841,362.405334 570.991821,361.997620 
	C570.991821,361.997620 570.982239,361.954132 571.622864,361.747070 
	C577.432861,356.891907 583.242920,352.036774 589.598694,347.007538 
	C589.811401,346.703064 590.024109,346.398621 590.066162,346.069366 
	C590.066162,346.069366 590.070679,346.241852 590.696106,346.036835 
M547.284973,730.696289 
	C547.284973,730.696289 547.004883,731.054443 546.404236,731.042114 
	C545.937988,731.366211 545.471802,731.690308 544.261658,732.092834 
	C541.220703,733.423584 538.179688,734.754272 533.904907,736.624939 
	C537.696228,736.404175 540.141052,736.261780 543.409180,736.493835 
	C544.288513,736.382202 545.167847,736.270508 546.661316,736.430603 
	C547.088989,736.330261 547.516602,736.229919 548.760254,736.496826 
	C550.523621,736.373169 552.287048,736.249451 554.932068,736.432861 
	C569.308350,736.330444 583.684692,736.228027 598.934937,736.439941 
	C604.902649,736.477539 610.870789,736.575623 616.838074,736.540222 
	C629.916321,736.462769 642.994141,736.317749 656.949219,736.505798 
	C667.724426,736.396973 678.499573,736.288147 689.896729,736.425171 
	C691.197327,736.261658 692.497986,736.098145 694.715759,736.054688 
	C703.495544,734.047363 712.275391,732.040039 721.787170,729.979675 
	C729.108337,725.471252 737.153442,721.812073 743.619507,716.300415 
	C766.025757,697.201294 772.749268,672.089417 769.428162,643.664307 
	C767.540894,627.511353 761.684387,612.821838 751.006165,600.571350 
	C745.663391,594.441956 740.189636,587.941467 732.053589,584.957275 
	C732.053589,584.957275 732.101379,584.931824 731.904114,584.264404 
	C728.282471,582.483276 724.660889,580.702148 721.293518,578.300598 
	C726.767822,573.511780 733.052063,569.380432 737.570007,563.815247 
	C753.342102,544.387207 757.915344,521.756653 752.566162,497.703522 
	C747.173523,473.454346 732.511047,456.203583 709.057495,446.954041 
	C709.057495,446.954041 709.107910,446.942993 708.930908,446.421021 
	C708.617249,446.295013 708.303528,446.169006 707.960815,445.987732 
	C707.960815,445.987732 707.911743,446.026337 707.772400,445.798035 
	C707.602600,445.657654 707.443298,445.668549 706.682251,445.241486 
	C697.096924,443.486084 687.570862,440.840515 677.911438,440.196594 
	C665.330566,439.357880 652.643860,440.107330 639.111450,439.993011 
	C636.085938,440.045258 633.060425,440.097504 629.108276,439.970673 
	C600.754211,440.038635 572.400208,440.106628 543.181763,439.991150 
	C540.743225,439.991150 538.304688,439.991150 534.712585,439.991150 
	C541.788696,443.423126 547.924866,446.151672 553.831543,449.309418 
	C573.293335,459.713715 589.315063,474.236267 603.664307,491.955292 
	C607.111145,492.265137 610.557983,492.574982 614.925598,493.069336 
	C632.957642,493.318512 650.989685,493.567688 669.491333,494.012329 
	C669.659851,493.993378 669.828308,493.974457 670.407654,494.356171 
	C670.937439,494.509460 671.467224,494.662750 672.636108,495.134094 
	C687.183289,501.584442 694.318481,513.479431 693.142700,529.264893 
	C692.214844,541.722900 684.904968,549.792480 674.828430,556.035583 
	C674.828430,556.035583 674.777832,556.039001 674.003906,556.065613 
	C672.575867,556.671448 671.147888,557.277283 668.861633,557.838867 
	C662.717712,558.115051 656.576355,558.511780 650.429382,558.639771 
	C640.619751,558.844055 630.806396,558.869507 620.088440,558.713928 
	C613.714355,558.791260 607.340332,558.868652 600.060913,558.696777 
	C591.031555,558.778687 582.002136,558.860596 572.062073,558.713745 
	C564.164429,558.713745 556.266785,558.713745 547.803833,558.713745 
	C552.552551,573.041992 552.223267,587.019775 551.789429,601.119507 
	C551.789429,601.119507 551.926331,601.473816 551.472229,602.271667 
	C551.422546,602.875305 551.372864,603.479004 551.084229,604.822937 
	C550.856628,606.103516 550.629028,607.384033 550.347107,608.970032 
	C552.086243,608.970032 553.574646,608.971497 555.062988,608.969727 
	C590.358948,608.929016 625.654907,608.887878 661.884644,608.981750 
	C670.186035,609.677917 678.487366,610.374084 687.176025,611.641479 
	C689.021973,612.485474 690.867981,613.329468 692.924438,614.670837 
	C693.253479,614.790100 693.582581,614.909363 694.247803,615.615417 
	C698.225525,620.578064 703.392090,624.999268 705.947205,630.609741 
	C712.361084,644.693420 709.903687,658.290039 700.850159,670.763245 
	C700.850159,670.763245 700.846313,670.730347 700.324341,670.939453 
	C700.155396,671.267029 699.986389,671.594604 699.933105,671.939209 
	C699.933105,671.939209 699.922607,671.822754 699.313354,672.055908 
	C699.091797,672.427063 698.870239,672.798157 698.056091,673.366028 
	C691.001648,680.006226 682.581604,683.155457 672.030518,683.023132 
	C650.797974,683.031921 629.564819,682.970642 608.334106,683.172913 
	C606.486938,683.190491 604.656250,684.944031 602.924011,685.928772 
	C602.924011,685.928772 602.931152,685.816406 602.398010,686.001221 
	C602.233765,686.327209 602.069519,686.653259 601.384521,687.196045 
	C600.979736,687.805115 600.574951,688.414124 599.790039,689.312378 
	C599.790039,689.312378 599.678589,689.776794 599.820190,689.856995 
	C599.820190,689.856995 599.839600,689.695435 599.284912,689.884888 
	C599.107361,690.220886 598.929810,690.556824 598.902527,690.911865 
	C598.902527,690.911865 598.881042,690.761902 598.341980,690.947632 
	C598.185608,691.287048 598.029297,691.626404 597.970215,691.973694 
	C597.970215,691.973694 597.965881,691.876282 597.384949,692.094238 
	C597.178223,692.447205 596.971497,692.800232 596.169250,693.372498 
	C583.201477,707.904602 567.775574,719.323242 550.423584,729.057129 
	C549.959229,729.375122 549.494873,729.693054 549.008728,729.992798 
	C549.008728,729.992798 549.003967,730.020386 548.429688,730.024048 
	C548.179565,730.282288 547.929443,730.540527 547.843201,730.748108 
	C547.843201,730.748108 547.732666,730.617065 547.284973,730.696289 
M613.018494,789.079346 
	C613.018494,787.172668 613.018494,785.265991 613.018494,783.265503 
	C610.320129,783.265503 608.171265,783.265503 605.795898,783.265503 
	C605.902405,785.111816 605.986084,786.562622 605.946655,788.764893 
	C605.998291,789.519104 606.049866,790.273315 605.923096,791.850647 
	C605.980591,793.246582 606.038025,794.642517 605.934875,796.957520 
	C605.986877,805.956726 606.038818,814.955933 605.895569,824.797913 
	C605.958313,826.522583 606.021118,828.247253 605.891602,830.862976 
	C605.986755,832.270630 606.081848,833.678284 606.172241,835.015198 
	C608.568787,835.015198 610.495789,835.015198 612.833191,835.015198 
	C612.833191,831.550720 612.833191,828.302124 612.961121,824.117188 
	C612.912170,814.732849 612.863281,805.348450 612.974731,795.123230 
	C612.929443,793.401123 612.884216,791.678955 613.018494,789.079346 
M767.190308,828.679260 
	C767.299988,828.492310 767.346863,828.290771 767.895447,827.588257 
	C767.945312,827.094482 767.995178,826.600708 768.729553,825.777405 
	C771.838928,816.949280 766.905884,809.329956 756.712341,805.882751 
	C752.193970,804.354675 748.173889,801.353149 743.992920,798.543335 
	C743.812012,798.113892 743.631104,797.684448 743.566895,796.523804 
	C743.514771,796.036499 743.462585,795.549255 743.773499,794.508118 
	C745.193848,792.675781 746.614197,790.843445 748.802917,788.960083 
	C751.725769,788.993896 754.648621,789.027710 757.979553,789.669312 
	C759.622620,792.002686 761.265686,794.336060 762.877808,796.625488 
	C764.760376,796.625488 766.807007,796.625488 769.792847,796.625488 
	C768.588806,794.020874 767.672546,792.038879 766.498413,789.429565 
	C762.902039,779.784607 744.304504,778.969238 738.246094,789.273438 
	C737.884033,791.195801 737.521912,793.118103 737.018188,795.874329 
	C735.841919,803.358337 741.963318,808.499573 747.861877,810.501892 
	C750.668884,811.454773 753.579590,812.152527 756.295349,813.312195 
	C761.001160,815.321838 763.859192,818.625061 762.829285,824.233093 
	C762.829285,824.233093 762.889465,824.292786 762.211670,824.790283 
	C762.059326,825.335144 761.906982,825.880066 761.510010,826.520569 
	C761.510010,826.520569 761.382568,826.750244 760.653259,826.829102 
	C756.720215,830.571289 752.122559,830.066101 747.014771,828.291931 
	C745.332153,825.990051 743.649536,823.688171 741.609131,820.896851 
	C740.423462,820.896851 738.304932,820.896851 734.706482,820.896851 
	C736.436340,824.383057 737.814453,827.160400 739.355591,830.593140 
	C748.530823,838.487000 760.739502,837.777954 766.994507,828.939087 
	C766.994507,828.939087 766.947693,828.879639 767.190308,828.679260 
M697.029602,790.097351 
	C697.029602,787.840759 697.029602,785.584229 697.029602,783.296753 
	C694.353088,783.296753 692.315796,783.296753 690.155396,783.296753 
	C690.155396,785.744385 690.155396,787.867004 690.017517,790.747009 
	C690.069641,791.509888 690.121765,792.272827 690.022522,793.957642 
	C690.073730,804.961792 690.124939,815.965881 690.003784,827.720398 
	C690.052368,828.487854 690.101013,829.255249 689.983643,830.911194 
	C690.083496,832.312195 690.183411,833.713196 690.264893,834.856201 
	C699.515686,834.856201 708.221619,834.856201 716.916199,834.856201 
	C716.916199,832.682556 716.916199,830.918884 716.916199,828.882935 
	C710.250427,828.882935 703.827515,828.882935 697.167419,828.063965 
	C697.060059,826.362366 696.952759,824.660767 696.989746,822.036926 
	C696.940369,813.367371 696.890991,804.697815 697.013489,795.200012 
	C696.962341,793.793091 696.911133,792.386230 697.029602,790.097351 
M647.089172,828.800354 
	C647.089172,828.800354 647.157776,828.721558 647.045227,827.825012 
	C646.967712,816.866882 646.890198,805.908752 647.003296,794.054688 
	C647.003296,790.448730 647.003296,786.842712 647.003296,783.287354 
	C644.147827,783.287354 642.070312,783.287354 640.000305,783.287354 
	C640.000305,800.050354 640.000305,816.499023 639.855713,833.631897 
	C640.247192,834.062866 640.638672,834.493896 641.925293,835.201294 
	C649.193909,835.220642 656.463257,835.187744 663.730713,835.288757 
	C666.170288,835.322693 667.672241,834.823547 666.988770,831.082947 
	C665.918762,830.311707 664.876038,828.922119 663.774109,828.873535 
	C658.202271,828.627930 652.615173,828.731140 647.089172,828.800354 
M575.598816,786.404968 
	C567.089417,779.898132 557.533142,784.112122 548.038269,782.754272 
	C548.221619,785.891357 548.345764,788.015442 548.342224,791.031067 
	C548.338196,794.368896 548.334167,797.706726 548.116638,801.483521 
	C548.116638,801.483521 548.318604,801.927795 548.231873,802.863342 
	C548.259766,807.261536 548.287659,811.659790 548.113647,816.514282 
	C548.113647,816.514282 548.328186,816.964722 548.253601,817.866516 
	C548.338867,820.565063 548.424133,823.263611 548.272034,826.691528 
	C548.338196,827.451477 548.404358,828.211487 548.244080,829.786011 
	C548.244080,831.425049 548.244080,833.064087 548.244080,834.971619 
	C556.362244,834.971619 564.133606,835.233521 571.866089,834.801270 
	C573.997070,834.682129 576.012329,832.492249 578.714905,830.942444 
	C581.449829,827.121826 584.373169,823.358582 582.576233,817.365845 
	C580.070923,813.786743 577.565674,810.207581 574.891113,806.386658 
	C578.527222,803.032288 580.325562,798.067383 578.736633,791.412292 
	C577.733521,789.970398 576.730408,788.528564 575.598816,786.404968 
M644.004639,368.992523 
	C644.004639,368.992523 643.988525,368.970459 644.507568,368.500000 
	C644.783264,366.795349 645.058960,365.090668 645.363586,363.207214 
	C635.517212,363.207214 626.234802,363.207214 616.036072,363.059906 
	C605.974854,364.872742 598.884033,361.737671 596.198608,351.195251 
	C596.103699,350.822357 595.619202,350.548676 595.192078,350.097656 
	C591.879883,352.774872 588.591736,355.282166 585.513245,358.024841 
	C584.725708,358.726471 583.924683,360.507416 584.279175,361.223541 
	C587.128906,366.980896 589.220276,373.726715 593.690063,377.834747 
	C596.290649,380.224915 602.839844,378.320343 607.612305,378.339264 
	C607.769592,378.339905 607.927307,378.242493 608.824402,378.486176 
	C609.864319,378.374878 610.904297,378.263550 612.611755,378.361176 
	C613.065796,378.284424 613.519836,378.207642 614.712158,378.477692 
	C622.624268,378.438171 630.539612,378.253296 638.447021,378.427399 
	C641.825317,378.501801 643.476562,377.529663 643.460083,373.607666 
	C643.505554,373.037354 643.551025,372.467072 643.851929,371.203186 
	C643.894714,370.467621 643.937439,369.732025 644.004639,368.992523 
M458.955383,378.482483 
	C462.336456,378.377319 465.717499,378.272156 469.972321,378.463165 
	C475.283569,378.498688 480.599579,378.668213 485.902771,378.478699 
	C487.286255,378.429230 489.295532,377.623230 489.880463,376.546875 
	C493.008392,370.791168 495.776123,364.839752 498.727234,358.836304 
	C494.450867,355.657074 490.556427,352.761780 486.427216,349.691956 
	C484.610443,353.376373 482.852081,356.398071 481.612762,359.619507 
	C480.526001,362.444519 478.773499,363.149658 475.941833,363.077667 
	C470.305725,362.934357 464.660706,363.143097 458.104553,363.000458 
	C450.912781,363.000458 443.721008,363.000458 436.268158,363.000458 
	C437.317261,367.705780 438.036102,371.775024 439.216187,375.705780 
	C439.568420,376.879028 441.187012,378.458984 442.268280,378.493073 
	C447.528961,378.658936 452.804199,378.363525 458.955383,378.482483 
M501.048706,395.671356 
	C500.126740,395.730164 499.204742,395.788971 497.498383,395.505707 
	C492.008514,395.458130 486.517944,395.331665 481.028931,395.379730 
	C470.236298,395.474182 459.444519,395.665619 448.226440,395.637573 
	C447.906708,395.579865 447.610138,395.638763 446.726227,395.494232 
	C445.391052,395.597961 444.055878,395.701660 442.044373,396.252472 
	C442.772003,401.113556 443.499634,405.974640 444.237762,410.905762 
	C464.482574,410.905762 484.249390,410.905762 504.933624,411.102661 
	C549.177368,411.102661 593.421082,411.102661 637.956787,411.102661 
	C638.823914,405.757965 639.600342,400.972229 640.416992,395.938843 
	C638.469543,395.816589 637.325684,395.685486 636.181396,395.681671 
	C607.407349,395.586121 578.633362,395.467590 549.859253,395.442932 
	C541.898926,395.436096 533.938416,395.715515 525.072266,395.694519 
	C521.129211,395.751984 517.186157,395.809448 512.492432,395.525452 
	C508.902618,395.633820 505.312775,395.742188 501.048706,395.671356 
M367.435547,705.049072 
	C415.683136,741.119873 468.748657,746.490295 523.623596,725.376709 
	C569.680847,707.655823 598.663330,672.802856 610.909790,624.110229 
	C590.951294,624.110229 571.627686,624.041382 552.307251,624.257141 
	C550.944092,624.272339 548.997681,626.112854 548.341858,627.565002 
	C528.509277,671.482788 480.342682,691.309204 434.301880,677.149109 
	C389.779358,663.456177 361.482117,619.047852 372.923431,566.789612 
	C381.324341,528.418396 406.071014,504.896118 444.686310,496.776794 
	C484.023499,488.505676 523.063965,505.653839 543.629028,539.917725 
	C545.012817,542.223328 546.371643,543.099121 549.050781,543.060669 
	C560.214478,542.900024 571.381775,542.988403 582.547791,542.986877 
	C590.977722,542.985657 599.407715,542.986572 609.254700,542.986572 
	C605.041443,533.461792 601.937073,524.477722 597.326355,516.347839 
	C572.229797,472.096069 534.177246,446.985168 483.687225,440.836639 
	C458.559906,437.776672 433.752197,439.568390 409.870575,448.140411 
	C344.000702,471.783478 303.314880,534.260132 313.272217,610.366028 
	C318.317139,648.925476 336.741669,680.179626 367.435547,705.049072 
M352.934174,793.421448 
	C354.597931,795.477234 357.057007,797.293640 357.762299,799.637817 
	C359.300598,804.750610 362.416260,805.257568 366.722870,804.087585 
	C363.881531,789.617676 351.949524,780.813904 337.126556,782.211365 
	C322.735382,783.568054 312.398041,794.733398 312.390350,809.188721 
	C312.381958,824.959717 324.298706,835.708862 338.383118,836.474792 
	C352.222321,837.227356 364.245758,828.324097 366.778564,814.295471 
	C362.469116,813.178223 359.801666,813.898499 357.796112,818.800781 
	C354.076294,827.893250 342.390839,831.973633 332.853668,828.373474 
	C325.150177,825.465576 320.765472,819.874756 319.964661,811.665344 
	C319.136566,803.176270 321.931305,796.280945 329.500275,791.840149 
	C337.312042,787.256958 344.951172,787.951782 352.934174,793.421448 
M397.626343,782.941528 
	C395.476349,782.941528 393.326355,782.941528 391.084778,782.941528 
	C391.084778,800.648682 391.084778,818.057983 391.084778,835.561279 
	C400.798096,835.561279 410.240479,835.561279 419.868439,835.561279 
	C419.868439,833.330383 419.868439,831.265015 419.868439,828.910156 
	C412.459686,828.910156 405.348846,828.910156 398.202637,828.910156 
	C398.202637,823.239441 398.202637,817.866150 398.202637,812.218262 
	C404.656738,812.218262 410.779541,812.218262 417.168213,812.218262 
	C417.168213,810.043152 417.168213,808.151550 417.168213,805.825439 
	C410.718079,805.825439 404.468689,805.825439 398.176331,805.825439 
	C398.176331,800.151062 398.176331,794.913208 398.176331,789.320923 
	C405.584045,789.320923 412.687561,789.320923 419.753174,789.320923 
	C419.753174,787.037415 419.753174,785.121460 419.753174,782.941467 
	C412.527679,782.941467 405.560150,782.941467 397.626343,782.941528 
M524.338379,832.429688 
	C524.687927,829.937500 523.881958,828.903870 521.104309,829.000122 
	C514.832703,829.217407 508.548279,829.068359 502.303131,829.068359 
	C502.303131,823.203369 502.303131,817.837097 502.303131,812.186035 
	C508.964264,812.186035 515.274353,812.186035 521.618408,812.186035 
	C521.618408,809.986023 521.618408,808.083618 521.618408,805.707458 
	C514.923828,805.707458 508.512299,805.707458 502.171783,805.707458 
	C502.171783,800.095947 502.171783,794.972778 502.171783,789.459961 
	C509.489471,789.459961 516.593994,789.459961 523.811768,789.459961 
	C523.811768,787.145081 523.811768,785.224670 523.811768,783.152771 
	C514.193604,783.152771 504.779877,783.152771 495.344543,783.152771 
	C495.344543,800.733948 495.344543,818.101135 495.344543,835.726990 
	C504.348816,835.726990 512.997314,835.800720 521.640442,835.618774 
	C522.555725,835.599487 523.439453,834.079895 524.338379,832.429688 
M451.962921,824.392334 
	C451.962921,810.642639 451.962921,796.893005 451.962921,783.249084 
	C449.306854,783.249084 447.241699,783.249084 445.218811,783.249084 
	C445.218811,800.801025 445.218811,818.040405 445.218811,835.474670 
	C454.188141,835.474670 462.922577,835.474670 471.866974,835.474670 
	C471.866974,833.306396 471.866974,831.393372 471.866974,829.068298 
	C466.218872,829.068298 460.752869,828.882935 455.307800,829.140198 
	C452.205383,829.286804 451.594940,827.938660 451.962921,824.392334 
z"/>
<path fill="#575757" opacity="1.000000" stroke="none" 
	d="
M1079.935181,2.127859 
	C1075.772827,2.085058 1071.610352,2.004845 1067.447998,2.004778 
	C712.267761,1.999038 357.087494,1.999498 1.453632,1.999760 
	C1.166667,1.666667 1.315919,1.056198 1.502793,1.044456 
	C2.830883,0.961004 4.166667,1.000000 5.500000,1.000000 
	C364.000000,1.000000 722.500000,1.000000 1080.733032,1.526012 
	C1080.289062,2.077302 1080.112061,2.102581 1079.935181,2.127859 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M1079.963623,2.564678 
	C1080.112061,2.102581 1080.289062,2.077302 1080.733032,2.026012 
	C1081.000000,361.666656 1081.000000,721.333313 1080.500000,1081.000000 
	C1079.997437,721.667175 1079.994873,362.334320 1079.963623,2.564678 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M367.145752,704.844849 
	C336.741669,680.179626 318.317139,648.925476 313.272217,610.366028 
	C303.314880,534.260132 344.000702,471.783478 409.870575,448.140411 
	C433.752197,439.568390 458.559906,437.776672 483.687225,440.836639 
	C534.177246,446.985168 572.229797,472.096069 597.326355,516.347839 
	C601.937073,524.477722 605.041443,533.461792 609.254700,542.986572 
	C599.407715,542.986572 590.977722,542.985657 582.547791,542.986877 
	C571.381775,542.988403 560.214478,542.900024 549.050781,543.060669 
	C546.371643,543.099121 545.012817,542.223328 543.629028,539.917725 
	C523.063965,505.653839 484.023499,488.505676 444.686310,496.776794 
	C406.071014,504.896118 381.324341,528.418396 372.923431,566.789612 
	C361.482117,619.047852 389.779358,663.456177 434.301880,677.149109 
	C480.342682,691.309204 528.509277,671.482788 548.341858,627.565002 
	C548.997681,626.112854 550.944092,624.272339 552.307251,624.257141 
	C571.627686,624.041382 590.951294,624.110229 610.909790,624.110229 
	C598.663330,672.802856 569.680847,707.655823 523.623596,725.376709 
	C468.748657,746.490295 415.683136,741.119873 367.145752,704.844849 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M551.000610,729.033081 
	C567.775574,719.323242 583.201477,707.904602 596.726074,693.104126 
	C597.510498,692.515991 597.738220,692.196167 597.965942,691.876282 
	C597.965881,691.876282 597.970215,691.973694 598.171692,691.847839 
	C598.542480,691.401978 598.711792,691.081970 598.881042,690.761902 
	C598.881042,690.761902 598.902527,690.911865 599.105713,690.806519 
	C599.485840,690.365967 599.662720,690.030701 599.839600,689.695435 
	C599.839600,689.695435 599.820190,689.856995 599.945190,689.832275 
	C600.189026,689.764038 600.307861,689.720581 600.426636,689.677124 
	C600.341187,689.459167 600.255676,689.241150 600.170166,689.023193 
	C600.574951,688.414124 600.979736,687.805115 601.887573,686.966431 
	C602.570801,686.430054 602.750977,686.123230 602.931152,685.816406 
	C602.931152,685.816406 602.924011,685.928772 603.284119,685.904785 
	C609.605652,685.880798 615.567078,685.880798 621.528564,685.880798 
	C621.770813,686.485352 622.013062,687.089966 622.255310,687.694519 
	C620.289062,688.818665 618.322815,689.942871 616.464233,691.005432 
	C619.914734,696.641846 624.266235,697.922058 629.068787,697.965698 
	C639.062683,698.056519 649.058228,697.959900 659.517639,697.944702 
	C661.799744,697.952698 663.617249,697.960999 665.434814,697.959290 
	C675.510315,697.949646 685.490784,697.359253 694.799805,692.915771 
	C698.198120,691.293701 701.557861,689.590881 705.391479,687.950562 
	C717.980957,688.316284 730.116455,688.597107 742.243835,689.082642 
	C743.545044,689.134705 744.798584,690.379211 745.921997,691.375244 
	C742.484558,696.080994 739.199219,700.481934 735.598633,704.946045 
	C734.191467,705.678894 733.099548,706.348633 732.004150,706.603027 
	C732.313293,702.327148 729.909485,701.957458 726.885315,701.970947 
	C712.388794,702.035645 697.742310,703.265381 683.445007,701.575989 
	C673.070190,700.350037 665.511841,704.000916 657.613342,708.901489 
	C657.052795,709.249329 656.486145,709.593323 655.896484,709.887207 
	C649.921814,712.865906 648.607971,716.942261 651.542358,721.983276 
	C642.642578,721.996765 634.212036,722.069092 625.783203,721.978271 
	C621.037903,721.927124 616.069214,721.042419 614.442749,727.474548 
	C614.246765,728.249695 613.054871,728.773071 613.019531,728.804016 
	C613.019531,731.411499 613.019531,733.210632 612.552368,735.011353 
	C607.402710,735.029724 602.720276,735.046570 597.565552,735.042175 
	C584.267334,735.021179 571.441040,734.984741 558.615540,735.070435 
	C557.091553,735.080627 555.572021,735.757751 554.050415,736.125732 
	C552.287048,736.249451 550.523621,736.373169 548.268921,736.128479 
	C547.200745,735.893005 546.623962,736.025940 546.047180,736.158875 
	C545.167847,736.270508 544.288513,736.382202 542.951416,736.302917 
	C542.493591,736.112000 542.487549,736.017822 542.649658,735.706665 
	C542.846191,734.930542 542.880615,734.465515 543.179565,733.869507 
	C543.964600,733.163879 544.485046,732.589111 545.005554,732.014404 
	C545.471802,731.690308 545.937988,731.366211 546.831909,731.195801 
	C547.417297,731.105347 547.574951,730.861206 547.732666,730.617065 
	C547.732666,730.617065 547.843201,730.748108 548.034912,730.745972 
	C548.485779,730.502686 548.744873,730.261536 549.003967,730.020386 
	C549.003967,730.020386 549.008728,729.992798 549.304626,729.954346 
	C550.067200,729.621643 550.533936,729.327393 551.000610,729.033081 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M603.289612,491.153412 
	C589.315063,474.236267 573.293335,459.713715 553.831543,449.309418 
	C547.924866,446.151672 541.788696,443.423126 534.712585,439.991150 
	C538.304688,439.991150 540.743225,439.991150 543.934998,440.337341 
	C555.669006,440.786621 566.651550,440.798584 577.630005,441.018433 
	C588.584290,441.237823 599.535217,441.641846 610.484985,442.039734 
	C611.544678,442.078247 613.003845,442.316284 613.559265,443.024841 
	C615.544556,445.557434 617.000732,448.522705 619.097107,450.943207 
	C620.305969,452.339020 622.323730,453.781860 624.039490,453.861267 
	C631.353882,454.199768 638.691589,454.037659 645.977051,454.404114 
	C642.924805,458.758850 645.032471,461.838745 648.361877,463.681458 
	C655.399414,467.576447 662.737183,470.931519 669.983459,474.442810 
	C670.668335,474.774628 671.582336,474.747284 672.381775,474.710144 
	C676.826599,474.503571 681.282532,474.387115 685.709534,473.972168 
	C697.822693,472.836792 710.061401,476.437988 722.397583,473.122681 
	C726.138184,477.850128 729.584412,482.423279 732.676270,487.145569 
	C728.801575,487.555908 725.285156,487.929932 721.759827,488.057068 
	C711.507812,488.426971 701.252136,488.697968 690.697571,488.857788 
	C688.616211,487.789917 686.835266,486.872009 684.900146,485.672302 
	C683.832825,484.922058 682.919739,484.453644 681.713501,483.855530 
	C680.618103,483.465759 679.815857,483.205658 678.827332,482.623169 
	C671.421936,478.951263 663.742554,478.759369 655.531372,478.997009 
	C649.237122,478.993561 643.407471,478.858124 637.587341,479.036041 
	C632.473694,479.192352 627.226379,479.071289 622.298096,480.204010 
	C615.450745,481.777771 615.419373,482.496124 617.711914,488.739746 
	C616.776978,489.869202 615.894592,490.935211 614.762817,492.008118 
	C614.513428,492.015015 614.014648,492.026855 613.604797,492.015076 
	C609.893188,491.720032 606.591370,491.436737 603.289612,491.153412 
z"/>
<path fill="#F4E99A" opacity="1.000000" stroke="none" 
	d="
M735.913940,704.882935 
	C739.199219,700.481934 742.484558,696.080994 746.218201,691.197754 
	C748.472778,686.490601 750.278992,682.265747 752.349976,677.783081 
	C753.740662,671.018372 754.866638,664.511475 755.997681,657.547241 
	C755.000122,649.394958 753.997620,641.699890 752.997803,633.598389 
	C753.942139,629.390503 754.565552,625.733582 749.700317,623.955994 
	C749.219543,623.780334 749.021851,622.857971 748.670410,622.296204 
	C745.392334,617.056885 742.109253,611.820557 739.675293,607.936096 
	C728.636475,609.696716 719.262817,608.937317 709.935303,608.699585 
	C708.990051,608.675537 708.129822,607.323792 707.138062,606.746155 
	C703.782654,604.791687 700.385864,602.908203 696.797913,600.719727 
	C694.035645,599.290039 691.568909,597.451904 688.909302,597.108643 
	C678.956543,595.824280 668.940674,595.028931 658.554321,594.043701 
	C657.105713,594.030518 656.051270,594.023804 654.998169,593.554932 
	C655.640320,588.605591 656.054382,584.069214 657.014465,579.651428 
	C657.517700,577.335754 658.978760,575.228149 660.453247,573.020508 
	C666.598633,572.008789 672.298096,571.003174 678.232300,570.269775 
	C679.583618,570.548279 681.043030,571.019226 681.763062,570.487976 
	C687.943115,565.928223 694.364136,572.008301 701.531189,568.428711 
	C698.081787,577.091858 689.273315,576.480774 685.521973,582.180603 
	C701.151062,589.154297 718.829956,590.936279 733.190735,601.066223 
	C733.587830,601.346252 734.154724,601.687256 734.559937,601.603088 
	C736.607544,601.177673 738.627747,600.620728 740.657593,600.109924 
	C740.071045,598.512878 739.713440,596.778259 738.852844,595.345886 
	C736.718933,591.794250 734.355164,588.380737 732.083740,584.911682 
	C740.189636,587.941467 745.663391,594.441956 751.006165,600.571350 
	C761.684387,612.821838 767.540894,627.511353 769.428162,643.664307 
	C772.749268,672.089417 766.025757,697.201294 743.619507,716.300415 
	C737.153442,721.812073 729.108337,725.471252 721.139771,729.763550 
	C719.800232,726.529724 718.971985,723.666260 724.119995,724.708923 
	C725.843567,725.057983 727.863159,723.945801 729.747498,723.501587 
	C729.903931,723.040161 730.060425,722.578735 730.216858,722.117310 
	C728.269531,721.036682 726.322144,719.955994 723.818787,718.566833 
	C725.933899,716.351318 728.014221,714.304016 729.939453,712.119934 
	C732.006958,709.774536 733.928650,707.300720 735.913940,704.882935 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M732.068665,584.934448 
	C734.355164,588.380737 736.718933,591.794250 738.852844,595.345886 
	C739.713440,596.778259 740.071045,598.512878 740.657593,600.109924 
	C738.627747,600.620728 736.607544,601.177673 734.559937,601.603088 
	C734.154724,601.687256 733.587830,601.346252 733.190735,601.066223 
	C718.829956,590.936279 701.151062,589.154297 685.521973,582.180603 
	C689.273315,576.480774 698.081787,577.091858 701.531189,568.428711 
	C694.364136,572.008301 687.943115,565.928223 681.763062,570.487976 
	C681.043030,571.019226 679.583618,570.548279 678.234436,570.022217 
	C678.001831,569.502441 678.008484,569.007324 678.390320,569.000122 
	C681.721191,567.613831 685.167358,566.801147 687.516846,564.739014 
	C692.570496,560.303284 696.972534,555.133667 701.839661,550.471436 
	C703.078064,549.285156 705.052307,548.244507 706.700134,548.226929 
	C711.297546,548.178040 715.905273,548.646606 720.501099,549.010132 
	C727.581848,549.570312 732.302246,546.149048 734.952515,539.966492 
	C738.765381,531.071838 740.623413,521.734863 739.992188,511.558868 
	C739.320679,507.076752 738.647278,503.030609 737.937500,498.612335 
	C736.277588,494.492279 734.654114,490.744354 733.030579,486.996429 
	C729.584412,482.423279 726.138184,477.850128 722.237732,472.822693 
	C718.191406,469.239258 714.599304,466.110138 711.005127,462.579834 
	C710.850708,460.881195 711.187805,459.095886 710.463196,458.368927 
	C706.917053,454.811249 707.356201,450.989746 709.092163,446.915558 
	C732.511047,456.203583 747.173523,473.454346 752.566162,497.703522 
	C757.915344,521.756653 753.342102,544.387207 737.570007,563.815247 
	C733.052063,569.380432 726.767822,573.511780 721.196899,578.982056 
	C724.767334,581.419556 728.434326,583.175720 732.101379,584.931824 
	C732.101379,584.931824 732.053589,584.957275 732.068665,584.934448 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M739.990234,511.994873 
	C740.623413,521.734863 738.765381,531.071838 734.952515,539.966492 
	C732.302246,546.149048 727.581848,549.570312 720.501099,549.010132 
	C715.905273,548.646606 711.297546,548.178040 706.700134,548.226929 
	C705.052307,548.244507 703.078064,549.285156 701.839661,550.471436 
	C696.972534,555.133667 692.570496,560.303284 687.516846,564.739014 
	C685.167358,566.801147 681.721191,567.613831 678.388794,568.583008 
	C678.458862,564.942261 678.912292,561.711487 679.300049,558.948730 
	C677.533508,557.774658 676.188843,556.880920 674.844116,555.987183 
	C684.904968,549.792480 692.214844,541.722900 693.142700,529.264893 
	C694.318481,513.479431 687.183289,501.584442 672.406128,494.623932 
	C672.677612,493.762817 673.156799,493.147675 673.683960,493.103271 
	C677.490051,492.782776 680.318542,492.091888 677.776245,487.174530 
	C677.288208,486.230438 678.555908,484.378632 679.013550,482.945587 
	C679.815857,483.205658 680.618103,483.465759 681.765259,484.204590 
	C683.091431,485.106903 684.072876,485.530487 685.054260,485.954071 
	C686.835266,486.872009 688.616211,487.789917 690.840210,489.157654 
	C694.184753,492.746704 697.086304,495.885864 700.028809,499.375549 
	C701.783081,502.902832 703.197815,506.300720 705.324036,509.171753 
	C706.558716,510.839050 708.927917,512.676819 710.800415,512.691650 
	C720.527954,512.768799 730.259827,512.283936 739.990234,511.994873 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M485.006531,340.680573 
	C472.768738,330.761505 460.532867,320.840088 448.291443,310.925476 
	C446.012390,309.079620 443.711182,307.261139 440.329132,305.937134 
	C443.125732,319.358765 445.922333,332.780365 448.365662,346.496887 
	C447.285767,345.169586 446.772522,343.396790 445.766663,341.971649 
	C444.923401,340.776947 443.629517,339.390320 442.321075,339.118256 
	C434.821014,337.558533 434.799866,337.662933 433.555878,344.922882 
	C433.528198,345.084473 433.446686,345.236847 433.390259,345.393524 
	C429.860474,329.195709 426.330658,312.997864 423.202820,296.384583 
	C427.224121,297.077393 430.843445,298.185638 434.846619,299.411438 
	C435.920593,294.472748 438.786041,294.393097 442.839050,296.772552 
	C444.059906,297.489319 446.057251,296.883423 447.887390,296.883423 
	C446.330994,293.196625 445.213593,290.549744 444.096191,287.902863 
	C449.077209,291.687988 454.168396,295.337769 459.017059,299.285431 
	C470.372589,308.530792 481.719879,317.793335 492.820740,327.339386 
	C495.522827,329.663025 496.518585,329.071136 497.883453,326.442932 
	C503.982574,314.698273 510.136200,302.981934 516.837463,291.289795 
	C516.049438,295.496277 515.346252,299.124054 521.941345,297.073669 
	C522.486694,296.904083 523.274109,297.548767 523.838745,297.399078 
	C525.653931,296.917816 527.442566,296.305206 529.191040,295.615570 
	C530.197815,295.218506 531.107422,294.574982 532.009277,294.399902 
	C525.238098,308.202972 518.516541,321.647675 511.800690,335.095184 
	C510.823425,337.052032 509.873077,339.022308 508.646240,340.719482 
	C508.682617,337.162903 508.983063,333.873230 509.331451,330.058380 
	C505.503510,333.564636 502.266541,338.500610 497.120117,332.647919 
	C496.859802,332.351868 496.131927,332.217041 495.722473,332.340668 
	C491.613708,333.581177 487.526062,334.891510 483.565826,336.140167 
	C484.026917,337.593353 484.516724,339.136963 485.006531,340.680573 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M635.582642,337.074371 
	C637.625244,326.526581 639.667786,315.978760 641.915771,304.370239 
	C625.105347,317.916077 608.921631,330.956879 592.370605,343.663391 
	C591.414917,340.901398 590.826538,338.473755 590.460999,336.965393 
	C586.762329,337.744446 583.760010,338.384308 580.754333,339.007874 
	C577.523193,339.678223 574.288879,340.333282 570.901123,340.678497 
	C569.279846,337.593994 567.782471,334.841827 566.352112,332.055267 
	C560.184937,320.040955 554.037476,308.016541 548.238892,296.025330 
	C549.925354,296.562683 551.926575,297.894562 552.478088,297.445618 
	C556.303894,294.331177 558.011292,297.554016 560.722778,300.967773 
	C562.157166,298.416443 563.156494,296.638947 564.155884,294.861450 
	C568.773499,303.414642 573.415466,311.954803 577.996704,320.527466 
	C579.791504,323.886047 581.450073,327.317444 583.326355,331.023041 
	C597.396179,319.595642 611.283203,308.316681 625.515991,297.313538 
	C624.592041,299.742462 623.322388,301.895599 621.182495,305.524475 
	C634.573425,305.105194 646.354797,302.760651 658.097778,300.086914 
	C655.194092,313.481415 652.267700,326.871063 649.416870,340.276825 
	C649.080933,341.856720 649.160217,343.524902 648.670532,345.247437 
	C647.307739,335.175110 646.197449,334.419250 636.075317,337.018890 
	C635.916748,337.059601 635.747131,337.056854 635.582642,337.074371 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M525.977966,395.863617 
	C533.938416,395.715515 541.898926,395.436096 549.859253,395.442932 
	C578.633362,395.467590 607.407349,395.586121 636.181396,395.681671 
	C637.325684,395.685486 638.469543,395.816589 640.416992,395.938843 
	C639.600342,400.972229 638.823914,405.757965 637.956787,411.102661 
	C593.421082,411.102661 549.177368,411.102661 504.747925,410.747192 
	C506.161621,410.139740 507.761078,409.887726 509.671295,409.586731 
	C509.484985,408.617157 509.319305,407.862793 509.196869,407.101471 
	C507.575562,397.023804 507.578125,397.100372 517.916077,396.961517 
	C520.607849,396.925385 523.290894,396.247009 525.977966,395.863617 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M697.004883,600.997559 
	C700.385864,602.908203 703.782654,604.791687 707.138062,606.746155 
	C708.129822,607.323792 708.990051,608.675537 709.935303,608.699585 
	C719.262817,608.937317 728.636475,609.696716 739.675293,607.936096 
	C742.109253,611.820557 745.392334,617.056885 748.670410,622.296204 
	C749.021851,622.857971 749.219543,623.780334 749.700317,623.955994 
	C754.565552,625.733582 753.942139,629.390503 752.606934,633.549194 
	C746.197266,632.590393 740.419434,630.456299 733.905029,631.560730 
	C731.616394,631.948730 728.540466,629.197388 726.539490,632.319519 
	C725.060120,634.627686 723.755127,637.361694 723.431335,640.034302 
	C722.629944,646.649475 722.436157,653.338318 721.850586,660.336914 
	C719.795654,665.434265 717.888489,670.192200 715.716553,675.150024 
	C712.632263,678.232422 709.812683,681.114990 706.643188,683.933960 
	C704.678467,681.411804 703.063721,678.953247 701.437927,676.477966 
	C702.553528,675.007446 703.691284,673.507690 705.155701,671.577393 
	C703.295166,671.211487 702.057861,670.968201 700.820618,670.724854 
	C709.903687,658.290039 712.361084,644.693420 705.947205,630.609741 
	C703.392090,624.999268 698.225525,620.578064 694.496338,615.317566 
	C700.846558,614.629211 702.907593,610.667786 699.712646,605.486389 
	C698.795532,603.999084 697.906677,602.494324 697.004883,600.997559 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M551.323120,604.082642 
	C551.372864,603.479004 551.422546,602.875305 551.654053,601.793701 
	C551.835938,601.315796 551.999573,600.988403 551.999573,600.988403 
	C552.223267,587.019775 552.552551,573.041992 547.803833,558.713745 
	C556.266785,558.713745 564.164429,558.713745 572.806641,559.135132 
	C577.839050,559.706055 582.135620,560.091736 586.412109,559.934082 
	C590.184387,559.794983 592.088623,561.118408 591.993042,564.972961 
	C591.902222,568.632812 593.200928,573.186584 586.990662,572.984680 
	C583.928833,572.885193 582.911743,574.445129 582.976318,577.292114 
	C583.070251,581.437744 582.786316,585.604187 583.114990,589.725769 
	C583.231079,591.181519 584.410278,592.816956 585.560425,593.873657 
	C588.399475,596.482117 591.502563,598.803162 594.316895,601.091248 
	C591.174561,604.045959 589.084839,606.010925 586.527100,607.984741 
	C576.284302,608.000427 566.504517,607.832153 556.737610,608.111572 
	C553.447327,608.205688 551.850891,607.149719 551.323120,604.082642 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M586.995117,607.975891 
	C589.084839,606.010925 591.174561,604.045959 594.316895,601.091248 
	C591.502563,598.803162 588.399475,596.482117 585.560425,593.873657 
	C584.410278,592.816956 583.231079,591.181519 583.114990,589.725769 
	C582.786316,585.604187 583.070251,581.437744 582.976318,577.292114 
	C582.911743,574.445129 583.928833,572.885193 586.990662,572.984680 
	C593.200928,573.186584 591.902222,568.632812 591.993042,564.972961 
	C592.088623,561.118408 590.184387,559.794983 586.412109,559.934082 
	C582.135620,560.091736 577.839050,559.706055 573.261963,559.249512 
	C582.002136,558.860596 591.031555,558.778687 600.805420,559.126404 
	C608.032410,559.700806 614.515015,559.845520 620.998779,559.995117 
	C621.000000,560.000000 621.009766,560.002441 621.041016,560.351074 
	C624.789551,564.122681 626.619019,567.851440 622.998535,572.479492 
	C622.915771,572.585266 622.976868,572.803528 622.528381,572.972412 
	C619.722168,572.981323 617.358521,572.987000 614.528442,572.990662 
	C610.907227,572.990540 607.704651,573.340820 604.608521,572.915771 
	C599.478943,572.211609 596.846558,574.715149 596.522400,579.271973 
	C596.211121,583.648071 596.879639,588.099487 597.229492,592.508972 
	C597.266724,592.977844 598.042786,593.754333 598.495178,593.768188 
	C603.659607,593.926941 608.827698,593.965210 614.458130,594.030884 
	C618.927856,594.044250 622.934265,594.062317 626.952942,594.480408 
	C626.965149,598.154968 626.965149,601.429443 626.965149,605.638916 
	C623.365356,606.339478 619.147034,607.712036 614.882080,607.877136 
	C605.599060,608.236572 596.292236,607.980469 586.995117,607.975891 
z"/>
<path fill="#F7F7F7" opacity="1.000000" stroke="none" 
	d="
M352.627930,793.210693 
	C344.951172,787.951782 337.312042,787.256958 329.500275,791.840149 
	C321.931305,796.280945 319.136566,803.176270 319.964661,811.665344 
	C320.765472,819.874756 325.150177,825.465576 332.853668,828.373474 
	C342.390839,831.973633 354.076294,827.893250 357.796112,818.800781 
	C359.801666,813.898499 362.469116,813.178223 366.778564,814.295471 
	C364.245758,828.324097 352.222321,837.227356 338.383118,836.474792 
	C324.298706,835.708862 312.381958,824.959717 312.390350,809.188721 
	C312.398041,794.733398 322.735382,783.568054 337.126556,782.211365 
	C351.949524,780.813904 363.881531,789.617676 366.722870,804.087585 
	C362.416260,805.257568 359.300598,804.750610 357.762299,799.637817 
	C357.057007,797.293640 354.597931,795.477234 352.627930,793.210693 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M525.525146,395.779053 
	C523.290894,396.247009 520.607849,396.925385 517.916077,396.961517 
	C507.578125,397.100372 507.575562,397.023804 509.196869,407.101471 
	C509.319305,407.862793 509.484985,408.617157 509.671295,409.586731 
	C507.761078,409.887726 506.161621,410.139740 504.289215,410.648743 
	C484.249390,410.905762 464.482574,410.905762 444.237762,410.905762 
	C443.499634,405.974640 442.772003,401.113556 442.837311,396.128296 
	C444.865753,395.940796 446.101227,395.877502 447.336731,395.814240 
	C447.610138,395.638763 447.906708,395.579865 448.930176,395.821045 
	C465.850189,395.952301 482.066498,395.900055 498.282776,395.847839 
	C499.204742,395.788971 500.126740,395.730164 501.863708,395.851715 
	C506.200195,395.977020 509.721649,395.921936 513.243103,395.866882 
	C517.186157,395.809448 521.129211,395.751984 525.525146,395.779053 
z"/>
<path fill="#F7F7F7" opacity="1.000000" stroke="none" 
	d="
M398.109497,782.941528 
	C405.560150,782.941467 412.527679,782.941467 419.753174,782.941467 
	C419.753174,785.121460 419.753174,787.037415 419.753174,789.320923 
	C412.687561,789.320923 405.584045,789.320923 398.176331,789.320923 
	C398.176331,794.913208 398.176331,800.151062 398.176331,805.825439 
	C404.468689,805.825439 410.718079,805.825439 417.168213,805.825439 
	C417.168213,808.151550 417.168213,810.043152 417.168213,812.218262 
	C410.779541,812.218262 404.656738,812.218262 398.202637,812.218262 
	C398.202637,817.866150 398.202637,823.239441 398.202637,828.910156 
	C405.348846,828.910156 412.459686,828.910156 419.868439,828.910156 
	C419.868439,831.265015 419.868439,833.330383 419.868439,835.561279 
	C410.240479,835.561279 400.798096,835.561279 391.084778,835.561279 
	C391.084778,818.057983 391.084778,800.648682 391.084778,782.941528 
	C393.326355,782.941528 395.476349,782.941528 398.109497,782.941528 
z"/>
<path fill="#F7F7F7" opacity="1.000000" stroke="none" 
	d="
M524.338135,832.843018 
	C523.439453,834.079895 522.555725,835.599487 521.640442,835.618774 
	C512.997314,835.800720 504.348816,835.726990 495.344543,835.726990 
	C495.344543,818.101135 495.344543,800.733948 495.344543,783.152771 
	C504.779877,783.152771 514.193604,783.152771 523.811768,783.152771 
	C523.811768,785.224670 523.811768,787.145081 523.811768,789.459961 
	C516.593994,789.459961 509.489471,789.459961 502.171783,789.459961 
	C502.171783,794.972778 502.171783,800.095947 502.171783,805.707458 
	C508.512299,805.707458 514.923828,805.707458 521.618408,805.707458 
	C521.618408,808.083618 521.618408,809.986023 521.618408,812.186035 
	C515.274353,812.186035 508.964264,812.186035 502.303131,812.186035 
	C502.303131,817.837097 502.303131,823.203369 502.303131,829.068359 
	C508.548279,829.068359 514.832703,829.217407 521.104309,829.000122 
	C523.881958,828.903870 524.687927,829.937500 524.338135,832.843018 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M700.835388,670.744019 
	C702.057861,670.968201 703.295166,671.211487 705.155701,671.577393 
	C703.691284,673.507690 702.553528,675.007446 701.437927,676.477966 
	C703.063721,678.953247 704.678467,681.411804 706.506226,684.227783 
	C706.124451,685.698486 705.529785,686.811707 704.935181,687.924927 
	C701.557861,689.590881 698.198120,691.293701 694.799805,692.915771 
	C685.490784,697.359253 675.510315,697.949646 665.434814,697.959290 
	C663.617249,697.960999 661.799744,697.952698 659.452881,697.570801 
	C657.754578,693.054504 656.585510,688.916565 655.335571,684.492310 
	C661.370422,684.045898 667.156738,683.617920 672.943054,683.189941 
	C682.581604,683.155457 691.001648,680.006226 698.636047,673.109741 
	C699.451538,672.509949 699.687073,672.166382 699.922607,671.822754 
	C699.922607,671.822754 699.933105,671.939209 700.136597,671.822388 
	C700.508850,671.380493 700.677551,671.055420 700.846313,670.730347 
	C700.846313,670.730347 700.850159,670.763245 700.835388,670.744019 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M564.141602,294.889893 
	C563.156494,296.638947 562.157166,298.416443 560.722778,300.967773 
	C558.011292,297.554016 556.303894,294.331177 552.478088,297.445618 
	C551.926575,297.894562 549.925354,296.562683 547.943115,295.844727 
	C544.497559,290.753754 541.703979,285.872955 538.573120,281.063293 
	C537.835815,282.110321 537.435791,283.086182 537.014282,284.041748 
	C536.992737,284.021393 537.051758,284.026154 536.687256,284.081726 
	C535.890076,285.438232 535.457275,286.739136 535.009460,288.024292 
	C534.994446,288.008545 535.035828,288.019623 534.673584,288.071655 
	C533.554993,290.106232 532.798706,292.088776 532.027710,294.052063 
	C532.012939,294.032806 532.060730,294.042419 532.060730,294.042419 
	C531.107422,294.574982 530.197815,295.218506 529.191040,295.615570 
	C527.442566,296.305206 525.653931,296.917816 523.838745,297.399078 
	C523.274109,297.548767 522.486694,296.904083 521.941345,297.073669 
	C515.346252,299.124054 516.049438,295.496277 517.139282,291.130981 
	C517.046509,290.382690 517.219238,289.827667 517.909668,288.971558 
	C518.611023,288.102936 518.794617,287.535431 518.978210,286.967896 
	C518.978210,286.967896 519.003723,286.992004 519.348022,286.928680 
	C520.128540,285.578308 520.564697,284.291290 521.000916,283.004242 
	C521.000916,283.004242 521.000000,283.000000 521.236572,282.848633 
	C521.666138,282.181976 521.859070,281.666656 522.052002,281.151367 
	C522.052063,281.151367 522.002014,281.062012 522.263062,280.847473 
	C522.639038,280.270294 522.753906,279.907654 522.868835,279.544983 
	C523.839722,277.561157 524.810669,275.577362 526.223755,273.567993 
	C527.642151,277.403748 529.780212,278.719757 533.645081,277.035004 
	C534.700317,276.575043 536.104675,276.923309 537.348328,276.886627 
	C541.520996,276.763550 545.709290,276.785919 549.849915,276.353394 
	C550.516846,276.283752 551.216614,274.224152 551.454773,272.997772 
	C551.696655,271.752106 551.386047,270.399170 551.317383,269.093201 
	C554.373657,274.860626 557.429932,280.628052 560.461548,286.844360 
	C560.349548,287.633759 560.488892,287.864532 560.854858,287.985535 
	C560.854858,287.985535 561.048645,288.385162 561.036682,289.022583 
	C561.112793,291.982880 560.365784,294.810120 564.179260,294.882965 
	C564.179260,294.882965 564.127258,294.918335 564.141602,294.889893 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M735.598633,704.946045 
	C733.928650,707.300720 732.006958,709.774536 729.939453,712.119934 
	C728.014221,714.304016 725.933899,716.351318 723.818787,718.566833 
	C726.322144,719.955994 728.269531,721.036682 730.216858,722.117310 
	C730.060425,722.578735 729.903931,723.040161 729.747498,723.501587 
	C727.863159,723.945801 725.843567,725.057983 724.119995,724.708923 
	C718.971985,723.666260 719.800232,726.529724 720.773804,729.790039 
	C712.275391,732.040039 703.495544,734.047363 693.929321,735.989807 
	C691.853455,736.009766 690.564148,736.094543 689.274780,736.179382 
	C678.499573,736.288147 667.724426,736.396973 656.689758,736.040405 
	C658.379028,735.384155 660.323730,735.112671 662.277161,735.016846 
	C669.394043,734.667786 676.512024,734.320923 683.633362,734.089905 
	C686.988403,733.980957 688.843201,733.099243 686.256775,729.283691 
	C688.447632,729.388794 690.070740,729.466675 692.793091,729.597229 
	C691.096741,727.631470 690.182800,726.572388 689.254395,725.496582 
	C691.407471,724.188293 693.204529,723.096375 695.400024,721.952393 
	C697.256653,721.606262 698.705872,721.254700 700.174377,721.027161 
	C712.034302,719.188660 722.882507,715.064880 732.007690,707.018372 
	C733.099548,706.348633 734.191467,705.678894 735.598633,704.946045 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M672.486816,683.106567 
	C667.156738,683.617920 661.370422,684.045898 655.335571,684.492310 
	C656.585510,688.916565 657.754578,693.054504 658.988403,697.566406 
	C649.058228,697.959900 639.062683,698.056519 629.068787,697.965698 
	C624.266235,697.922058 619.914734,696.641846 616.464233,691.005432 
	C618.322815,689.942871 620.289062,688.818665 622.255310,687.694519 
	C622.013062,687.089966 621.770813,686.485352 621.528564,685.880798 
	C615.567078,685.880798 609.605652,685.880798 603.231079,685.885986 
	C604.656250,684.944031 606.486938,683.190491 608.334106,683.172913 
	C629.564819,682.970642 650.797974,683.031921 672.486816,683.106567 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M696.797913,600.719727 
	C697.906677,602.494324 698.795532,603.999084 699.712646,605.486389 
	C702.907593,610.667786 700.846558,614.629211 694.328247,615.024170 
	C693.582581,614.909363 693.253479,614.790100 693.098145,614.223145 
	C692.425476,609.723267 689.170959,611.442017 686.788757,611.070251 
	C678.487366,610.374084 670.186035,609.677917 661.182617,608.682495 
	C659.467041,608.049866 658.453430,607.716431 656.358032,607.027161 
	C661.694153,604.531616 661.657349,601.273071 659.363953,597.426331 
	C658.832642,596.535156 659.066650,595.187683 658.948486,594.050171 
	C668.940674,595.028931 678.956543,595.824280 688.909302,597.108643 
	C691.568909,597.451904 694.035645,599.290039 696.797913,600.719727 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M695.001526,722.004456 
	C693.204529,723.096375 691.407471,724.188293 689.254395,725.496582 
	C690.182800,726.572388 691.096741,727.631470 692.793091,729.597229 
	C690.070740,729.466675 688.447632,729.388794 686.256775,729.283691 
	C688.843201,733.099243 686.988403,733.980957 683.633362,734.089905 
	C676.512024,734.320923 669.394043,734.667786 662.277161,735.016846 
	C660.323730,735.112671 658.379028,735.384155 656.251221,735.887695 
	C642.994141,736.317749 629.916321,736.462769 616.838074,736.540222 
	C610.870789,736.575623 604.902649,736.477539 598.495117,736.105225 
	C598.034363,735.535278 598.028564,735.299561 598.037781,735.063477 
	C602.720276,735.046570 607.402710,735.029724 613.018799,735.013123 
	C627.145081,734.640198 640.337891,734.267029 653.530640,733.893799 
	C653.202393,733.313171 652.874207,732.732483 652.545959,732.151794 
	C653.110291,730.495361 653.560120,728.787903 654.269470,727.196045 
	C655.061584,725.418518 656.089233,723.745911 657.482117,722.021912 
	C670.300415,722.012268 682.650940,722.008362 695.001526,722.004456 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M658.554321,594.043701 
	C659.066650,595.187683 658.832642,596.535156 659.363953,597.426331 
	C661.657349,601.273071 661.694153,604.531616 656.358032,607.027161 
	C658.453430,607.716431 659.467041,608.049866 660.715759,608.614990 
	C625.654907,608.887878 590.358948,608.929016 555.062988,608.969727 
	C553.574646,608.971497 552.086243,608.970032 550.347107,608.970032 
	C550.629028,607.384033 550.856628,606.103516 551.203674,604.452759 
	C551.850891,607.149719 553.447327,608.205688 556.737610,608.111572 
	C566.504517,607.832153 576.284302,608.000427 586.527100,607.984741 
	C596.292236,607.980469 605.599060,608.236572 614.882080,607.877136 
	C619.147034,607.712036 623.365356,606.339478 626.965149,605.638916 
	C626.965149,601.429443 626.965149,598.154968 627.376038,594.454346 
	C629.191284,594.018799 630.595642,594.009399 632.468628,593.999878 
	C640.290405,594.005615 647.643616,594.011353 654.996765,594.017090 
	C656.051270,594.023804 657.105713,594.030518 658.554321,594.043701 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M646.020752,454.058258 
	C638.691589,454.037659 631.353882,454.199768 624.039490,453.861267 
	C622.323730,453.781860 620.305969,452.339020 619.097107,450.943207 
	C617.000732,448.522705 615.544556,445.557434 613.559265,443.024841 
	C613.003845,442.316284 611.544678,442.078247 610.484985,442.039734 
	C599.535217,441.641846 588.584290,441.237823 577.630005,441.018433 
	C566.651550,440.798584 555.669006,440.786621 544.367188,440.429077 
	C572.400208,440.106628 600.754211,440.038635 629.877441,440.320068 
	C633.768494,440.767303 636.890381,440.865112 640.474487,440.972229 
	C644.956909,440.994141 648.976990,441.006775 652.997070,441.409668 
	C653.329102,445.869720 653.661011,449.939514 653.549805,454.015259 
	C650.744690,454.033569 648.382690,454.045898 646.020752,454.058258 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M444.081787,287.935516 
	C445.213593,290.549744 446.330994,293.196625 447.887390,296.883423 
	C446.057251,296.883423 444.059906,297.489319 442.839050,296.772552 
	C438.786041,294.393097 435.920593,294.472748 434.846619,299.411438 
	C430.843445,298.185638 427.224121,297.077393 423.172180,295.962769 
	C422.524414,295.481842 422.309357,295.007233 422.408600,294.230347 
	C422.490448,293.268250 422.257935,292.608490 422.025452,291.948730 
	C419.884918,282.984131 417.744415,274.019501 415.603882,265.054901 
	C415.919373,264.867004 416.234863,264.679108 416.550354,264.491180 
	C424.580994,271.074371 432.611603,277.657562 440.929504,284.789062 
	C441.548859,285.533722 441.881012,285.730072 442.213135,285.926422 
	C442.512939,285.886597 442.768066,285.975342 443.119446,286.684265 
	C443.553131,287.438995 443.845917,287.702087 444.138672,287.965210 
	C444.138672,287.965210 444.067352,287.968170 444.081787,287.935516 
z"/>
<path fill="#F7F7F7" opacity="1.000000" stroke="none" 
	d="
M451.963928,824.879517 
	C451.594940,827.938660 452.205383,829.286804 455.307800,829.140198 
	C460.752869,828.882935 466.218872,829.068298 471.866974,829.068298 
	C471.866974,831.393372 471.866974,833.306396 471.866974,835.474670 
	C462.922577,835.474670 454.188141,835.474670 445.218811,835.474670 
	C445.218811,818.040405 445.218811,800.801025 445.218811,783.249084 
	C447.241699,783.249084 449.306854,783.249084 451.962921,783.249084 
	C451.962921,796.893005 451.962921,810.642639 451.963928,824.879517 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M571.055908,340.994995 
	C574.288879,340.333282 577.523193,339.678223 580.754333,339.007874 
	C583.760010,338.384308 586.762329,337.744446 590.460999,336.965393 
	C590.826538,338.473755 591.414917,340.901398 592.025757,343.741089 
	C592.066223,344.413757 592.012939,344.658966 591.404541,345.075684 
	C590.637390,345.589050 590.354004,345.915466 590.070679,346.241852 
	C590.070679,346.241852 590.066162,346.069366 589.882446,346.174622 
	C589.483521,346.580505 589.268250,346.881073 589.052917,347.181641 
	C583.242920,352.036774 577.432861,356.891907 571.294312,361.441956 
	C569.905029,360.426392 568.919189,359.309296 567.766663,359.096863 
	C565.844788,358.742615 563.590027,359.531250 561.874878,358.843079 
	C556.687195,356.761658 553.381592,359.366791 549.798706,362.476257 
	C548.305054,363.772522 545.831238,363.939423 543.993286,364.357605 
	C551.473816,358.142517 558.764221,352.189697 566.308594,346.457550 
	C567.328613,345.718689 568.094727,344.759155 568.880493,343.855286 
	C568.900208,343.910889 568.782227,343.909882 569.052551,343.816986 
	C569.530212,343.415222 569.737549,343.106354 570.031677,342.541748 
	C570.430908,341.855652 570.743408,341.425323 571.055908,340.994995 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M578.726501,792.079468 
	C580.325562,798.067383 578.527222,803.032288 574.891113,806.386658 
	C577.565674,810.207581 580.070923,813.786743 582.169312,817.721436 
	C579.723450,819.395142 577.684631,820.713257 575.663940,821.642700 
	C575.539612,811.323364 569.425415,809.515686 554.797791,811.687012 
	C554.797791,815.754456 554.797791,819.875000 554.598633,823.980347 
	C554.399536,823.965088 554.000000,823.965820 554.003845,823.594604 
	C553.162231,821.810852 552.316772,820.398438 551.205444,818.541687 
	C550.032532,821.770020 549.270996,823.866089 548.509399,825.962158 
	C548.424133,823.263611 548.338867,820.565063 548.452026,817.352051 
	C548.538757,816.577698 548.427124,816.317871 548.315552,816.057983 
	C548.287659,811.659790 548.259766,807.261536 548.436768,802.341309 
	C548.678650,801.711304 548.778503,801.564453 548.740479,801.502930 
	C548.634216,801.331299 548.470886,801.194885 548.330139,801.044556 
	C548.334167,797.706726 548.338196,794.368896 548.743347,790.646729 
	C551.044067,791.523254 552.943665,792.784241 554.881226,794.503113 
	C554.919128,798.195496 554.919128,801.430054 554.919128,804.847473 
	C558.356201,804.847473 560.996216,804.903503 563.633118,804.836365 
	C570.152405,804.670593 572.656006,801.863098 572.774902,795.114929 
	C574.491882,795.710205 575.821594,796.302734 577.318848,796.969910 
	C577.873840,795.041748 578.300171,793.560608 578.726501,792.079468 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M459.019348,363.206390 
	C464.660706,363.143097 470.305725,362.934357 475.941833,363.077667 
	C478.773499,363.149658 480.526001,362.444519 481.612762,359.619507 
	C482.852081,356.398071 484.610443,353.376373 486.427216,349.691956 
	C490.556427,352.761780 494.450867,355.657074 498.727234,358.836304 
	C495.776123,364.839752 493.008392,370.791168 489.880463,376.546875 
	C489.295532,377.623230 487.286255,378.429230 485.902771,378.478699 
	C480.599579,378.668213 475.283569,378.498688 469.696350,378.023499 
	C478.586578,378.051453 474.697113,369.566742 478.753326,364.988861 
	C472.769562,364.605530 467.919586,364.294830 462.712555,363.981140 
	C461.243469,363.720917 460.131409,363.463654 459.019348,363.206390 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M652.997070,441.019409 
	C648.976990,441.006775 644.956909,440.994141 640.472595,440.775055 
	C640.008362,440.568604 640.002686,440.173401 640.002686,440.173401 
	C652.643860,440.107330 665.330566,439.357880 677.911438,440.196594 
	C687.570862,440.840515 697.096924,443.486084 707.095337,445.551270 
	C707.508484,445.861023 707.911743,446.026337 707.911743,446.026337 
	C707.911743,446.026337 707.960815,445.987732 708.102051,446.230347 
	C708.477600,446.728668 708.765808,446.885345 709.107910,446.942993 
	C709.107910,446.942993 709.057495,446.954041 709.074829,446.934814 
	C707.356201,450.989746 706.917053,454.811249 710.463196,458.368927 
	C711.187805,459.095886 710.850708,460.881195 710.671021,462.455688 
	C701.900940,460.117188 693.462952,457.501587 684.974609,454.535919 
	C684.040833,451.197815 680.745789,448.807098 682.751709,444.982819 
	C682.910095,444.680725 680.554688,442.223938 679.276917,442.139465 
	C670.530518,441.561218 661.759888,441.349365 652.997070,441.019409 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M608.084778,378.190735 
	C607.927307,378.242493 607.769592,378.339905 607.612305,378.339264 
	C602.839844,378.320343 596.290649,380.224915 593.690063,377.834747 
	C589.220276,373.726715 587.128906,366.980896 584.279175,361.223541 
	C583.924683,360.507416 584.725708,358.726471 585.513245,358.024841 
	C588.591736,355.282166 591.879883,352.774872 595.192078,350.097656 
	C595.619202,350.548676 596.103699,350.822357 596.198608,351.195251 
	C598.884033,361.737671 605.974854,364.872742 616.185913,363.366669 
	C606.317749,364.650970 604.101501,366.624481 603.707397,374.485931 
	C605.305176,375.838196 606.694946,377.014465 608.084778,378.190735 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M674.836304,556.011353 
	C676.188843,556.880920 677.533508,557.774658 679.300049,558.948730 
	C678.912292,561.711487 678.458862,564.942261 678.006958,568.590210 
	C678.008484,569.007324 678.001831,569.502441 677.999695,569.750000 
	C672.298096,571.003174 666.598633,572.008789 659.994263,573.011475 
	C655.723450,573.001587 652.357605,572.994690 649.034546,572.622803 
	C649.688599,570.907166 650.300964,569.557068 650.910706,568.205750 
	C653.304565,562.900696 651.520935,560.018738 645.799011,560.006348 
	C637.535950,559.988464 629.272827,560.002197 621.009766,560.002441 
	C621.009766,560.002441 621.000000,560.000000 621.001282,559.740234 
	C620.999939,559.310547 620.997253,559.140564 620.994629,558.970520 
	C630.806396,558.869507 640.619751,558.844055 650.429382,558.639771 
	C656.576355,558.511780 662.717712,558.115051 669.646851,558.001709 
	C672.797668,559.330750 674.576721,559.297913 674.777832,556.039001 
	C674.777832,556.039001 674.828430,556.035583 674.836304,556.011353 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M658.074402,300.074768 
	C646.354797,302.760651 634.573425,305.105194 621.182495,305.524475 
	C623.322388,301.895599 624.592041,299.742462 625.844849,297.224243 
	C625.828003,296.859100 625.865723,296.920227 626.094055,296.829590 
	C626.529236,296.452148 626.736023,296.165375 626.942810,295.878571 
	C626.942810,295.878571 626.964539,295.967468 627.301025,295.964691 
	C629.067932,294.603119 630.498230,293.244324 631.928589,291.885559 
	C631.928589,291.885559 631.955444,291.968750 632.159546,291.867004 
	C632.567261,291.492157 632.770935,291.219025 632.974609,290.945892 
	C632.974609,290.945892 632.997498,291.000092 633.346924,290.989838 
	C634.766113,289.939575 635.835938,288.899597 636.905762,287.859619 
	C636.905762,287.859619 636.957397,287.964081 637.241211,287.940765 
	C638.349243,287.611603 639.173401,287.305756 640.230347,287.275360 
	C646.640442,289.028656 652.817749,290.506531 658.997559,292.376251 
	C659.015015,293.551208 659.029907,294.334290 659.044800,295.117371 
	C659.044800,295.117371 659.019409,295.577850 658.709961,296.050934 
	C658.291992,297.718964 658.183533,298.913879 658.075073,300.108826 
	C658.075134,300.108826 658.051025,300.062592 658.074402,300.074768 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M458.561951,363.103424 
	C460.131409,363.463654 461.243469,363.720917 462.677612,364.424622 
	C462.286865,369.666992 466.712982,376.357422 458.074280,378.231384 
	C452.804199,378.363525 447.528961,378.658936 442.268280,378.493073 
	C441.187012,378.458984 439.568420,376.879028 439.216187,375.705780 
	C438.036102,371.775024 437.317261,367.705780 436.268158,363.000458 
	C443.721008,363.000458 450.912781,363.000458 458.561951,363.103424 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M543.803101,364.619812 
	C545.831238,363.939423 548.305054,363.772522 549.798706,362.476257 
	C553.381592,359.366791 556.687195,356.761658 561.874878,358.843079 
	C563.590027,359.531250 565.844788,358.742615 567.766663,359.096863 
	C568.919189,359.309296 569.905029,360.426392 570.973999,361.545502 
	C570.982239,361.954132 570.991821,361.997620 570.645569,362.050537 
	C569.900574,362.572937 569.501770,363.042419 569.102966,363.511902 
	C565.361206,366.650360 561.619446,369.788818 557.262451,372.814026 
	C555.531189,371.241760 554.482788,368.597473 553.287292,368.529175 
	C548.131348,368.234711 542.932922,368.720306 537.748352,368.861298 
	C536.615356,368.892120 535.402222,368.890167 534.351440,368.533722 
	C529.560669,366.908417 526.553528,368.155426 524.308289,372.865448 
	C522.671875,371.596283 521.035400,370.327148 519.174927,368.447266 
	C518.420593,366.494965 517.890381,365.153412 517.374695,363.848846 
	C521.816772,359.560822 526.871765,359.118774 531.934570,362.240631 
	C533.830811,363.409851 536.035461,364.078796 538.425659,365.102448 
	C539.835815,365.487701 540.919800,365.746338 542.126221,366.160065 
	C542.368408,366.065643 542.488098,365.816101 542.670288,365.609985 
	C542.732849,365.653442 542.588196,365.700989 542.893555,365.677979 
	C543.408447,365.326691 543.617920,364.998413 543.833252,364.652222 
	C543.839111,364.634277 543.803101,364.619812 543.803101,364.619812 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M524.489746,373.130127 
	C526.553528,368.155426 529.560669,366.908417 534.351440,368.533722 
	C535.402222,368.890167 536.615356,368.892120 537.748352,368.861298 
	C542.932922,368.720306 548.131348,368.234711 553.287292,368.529175 
	C554.482788,368.597473 555.531189,371.241760 556.947510,372.925385 
	C552.559082,377.109863 547.970703,381.201538 543.094604,384.915558 
	C542.147217,385.637238 539.633972,385.484070 538.604126,384.715057 
	C533.813416,381.137726 529.290222,377.202148 524.489746,373.130127 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M678.827332,482.623169 
	C678.555908,484.378632 677.288208,486.230438 677.776245,487.174530 
	C680.318542,492.091888 677.490051,492.782776 673.683960,493.103271 
	C673.156799,493.147675 672.677612,493.762817 672.086609,494.464905 
	C671.467224,494.662750 670.937439,494.509460 670.123596,493.998657 
	C669.566956,493.699707 669.294312,493.758270 669.021729,493.816864 
	C650.989685,493.567688 632.957642,493.318512 614.468567,492.762604 
	C614.011536,492.455872 614.014648,492.026855 614.014648,492.026855 
	C614.014648,492.026855 614.513428,492.015015 615.230103,492.012665 
	C628.158691,491.481201 640.478516,493.219940 651.173889,491.093781 
	C653.115906,486.227722 654.557983,482.614471 656.000000,479.001221 
	C663.742554,478.759369 671.421936,478.951263 678.827332,482.623169 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M658.995117,291.984375 
	C652.817749,290.506531 646.640442,289.028656 640.448792,287.167023 
	C640.706482,286.583557 640.893188,286.322784 640.949585,285.665894 
	C640.845032,285.113556 640.785339,284.896271 640.725708,284.678955 
	C640.725708,284.678955 640.837280,284.866180 640.938843,284.827667 
	C641.144775,284.552734 641.249146,284.316345 641.353516,284.079956 
	C649.366699,277.461121 657.379822,270.842285 665.608154,264.014862 
	C665.823364,263.806274 665.738586,263.803986 665.738586,263.803986 
	C664.367981,271.639862 663.001160,279.476410 661.622803,287.310913 
	C661.397217,288.593109 661.120117,289.866241 660.552734,291.247253 
	C659.824097,291.562073 659.409607,291.773224 658.995117,291.984375 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M538.099487,364.975830 
	C536.035461,364.078796 533.830811,363.409851 531.934570,362.240631 
	C526.871765,359.118774 521.816772,359.560822 517.374695,363.848846 
	C517.890381,365.153412 518.420593,366.494965 518.965210,368.165161 
	C514.105408,364.590515 509.231384,360.687195 504.075348,356.235779 
	C503.468140,355.484375 503.142944,355.281097 502.817719,355.077820 
	C502.817719,355.077820 502.917023,355.066986 502.908722,354.754150 
	C501.361908,351.888306 499.823395,349.335297 497.705444,345.820740 
	C500.954681,347.110229 503.148132,347.980713 505.555634,348.936157 
	C506.773193,347.197296 507.888397,345.604584 509.402863,344.009338 
	C510.600494,343.955475 511.398865,343.904083 512.144653,343.861145 
	C512.091980,343.869659 512.145203,343.777222 512.146301,344.149048 
	C512.902527,344.884888 513.657654,345.248932 514.336426,345.653503 
	C514.260010,345.694031 514.301147,345.526062 514.336853,345.855225 
	C514.939148,346.443604 515.505737,346.702820 516.040283,346.976593 
	C516.008301,346.991180 516.024536,346.922699 516.154907,347.159973 
	C516.597778,347.566742 516.910339,347.736206 517.149170,347.915375 
	C517.075439,347.925079 517.088562,347.776886 517.196960,348.047607 
	C517.643311,348.487762 517.981323,348.657166 518.232849,348.845642 
	C518.146423,348.864777 518.169189,348.689178 518.228394,348.968262 
	C518.570496,349.485931 518.853271,349.724518 519.482056,349.991699 
	C525.918457,355.005463 532.008972,359.990631 538.099487,364.975830 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M608.454590,378.338440 
	C606.694946,377.014465 605.305176,375.838196 603.707397,374.485931 
	C604.101501,366.624481 606.317749,364.650970 616.644043,363.440308 
	C626.234802,363.207214 635.517212,363.207214 645.363586,363.207214 
	C645.058960,365.090668 644.783264,366.795349 644.271606,368.323151 
	C644.209717,365.233551 642.906067,363.878510 639.923889,363.955872 
	C635.951660,364.058899 631.971802,363.895172 628.001282,364.027191 
	C624.455444,364.145142 621.584656,364.844086 621.917480,369.669159 
	C622.292542,375.106049 623.432190,376.820251 628.361938,376.982086 
	C631.174561,377.074432 634.063354,377.394440 636.785278,376.892792 
	C638.968079,376.490540 640.951233,375.005157 643.023010,374.000427 
	C643.476562,377.529663 641.825317,378.501801 638.447021,378.427399 
	C630.539612,378.253296 622.624268,378.438171 614.202393,378.059448 
	C613.109802,377.811584 612.527039,377.981903 611.944214,378.152252 
	C610.904297,378.263550 609.864319,378.374878 608.454590,378.338440 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M635.368164,337.361420 
	C635.747131,337.056854 635.916748,337.059601 636.075317,337.018890 
	C646.197449,334.419250 647.307739,335.175110 648.542114,345.650909 
	C648.084961,349.677002 647.379944,353.394897 646.616760,357.419006 
	C641.614258,357.419006 636.716614,357.419006 631.240417,357.419006 
	C632.557739,350.763794 633.855713,344.206146 635.368164,337.361420 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M509.003632,344.011841 
	C507.888397,345.604584 506.773193,347.197296 505.555634,348.936157 
	C503.148132,347.980713 500.954681,347.110229 497.705444,345.820740 
	C499.823395,349.335297 501.361908,351.888306 502.897827,354.802887 
	C499.329254,352.377472 495.763245,349.590515 491.944275,346.237762 
	C491.347382,345.521729 491.003479,345.371490 490.659576,345.221222 
	C490.659576,345.221222 490.800354,345.203247 490.817780,344.899048 
	C489.008820,343.362671 487.182434,342.130432 485.356049,340.898193 
	C485.356049,340.898193 485.086304,340.941864 485.046417,340.811218 
	C484.516724,339.136963 484.026917,337.593353 483.565826,336.140167 
	C487.526062,334.891510 491.613708,333.581177 495.722473,332.340668 
	C496.131927,332.217041 496.859802,332.351868 497.120117,332.647919 
	C502.266541,338.500610 505.503510,333.564636 509.331451,330.058380 
	C508.983063,333.873230 508.682617,337.162903 508.735474,340.912292 
	C509.088745,341.372040 509.080780,341.796661 509.053955,342.165100 
	C509.019318,343.026306 509.011475,343.519104 509.003632,344.011841 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M551.272217,268.768677 
	C551.386047,270.399170 551.696655,271.752106 551.454773,272.997772 
	C551.216614,274.224152 550.516846,276.283752 549.849915,276.353394 
	C545.709290,276.785919 541.520996,276.763550 537.348328,276.886627 
	C536.104675,276.923309 534.700317,276.575043 533.645081,277.035004 
	C529.780212,278.719757 527.642151,277.403748 526.430786,273.302124 
	C527.631409,270.181122 529.067261,267.300415 531.139099,264.247314 
	C537.299438,264.022949 542.823792,263.970978 548.348145,263.918976 
	C549.307800,265.427368 550.267456,266.935760 551.272217,268.768677 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M643.241577,373.804047 
	C640.951233,375.005157 638.968079,376.490540 636.785278,376.892792 
	C634.063354,377.394440 631.174561,377.074432 628.361938,376.982086 
	C623.432190,376.820251 622.292542,375.106049 621.917480,369.669159 
	C621.584656,364.844086 624.455444,364.145142 628.001282,364.027191 
	C631.971802,363.895172 635.951660,364.058899 639.923889,363.955872 
	C642.906067,363.878510 644.209717,365.233551 644.012085,368.558380 
	C643.988525,368.970459 644.004639,368.992523 643.763977,369.159180 
	C643.547668,370.182800 643.572021,371.039795 643.596436,371.896759 
	C643.551025,372.467072 643.505554,373.037354 643.241577,373.804047 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M433.397217,345.383972 
	C433.446686,345.236847 433.528198,345.084473 433.555878,344.922882 
	C434.799866,337.662933 434.821014,337.558533 442.321075,339.118256 
	C443.629517,339.390320 444.923401,340.776947 445.766663,341.971649 
	C446.772522,343.396790 447.285767,345.169586 448.341125,346.885437 
	C449.484772,350.478333 450.299652,353.977600 451.180023,357.757965 
	C446.040710,357.757965 441.297913,357.757965 436.183990,357.757965 
	C435.490082,354.781372 434.789612,351.776428 434.360229,348.146118 
	C434.224182,346.812408 433.816956,346.104065 433.409729,345.395721 
	C433.409729,345.395721 433.404205,345.374420 433.397217,345.383972 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M762.764648,824.180542 
	C763.859192,818.625061 761.001160,815.321838 756.295349,813.312195 
	C753.579590,812.152527 750.668884,811.454773 747.861877,810.501892 
	C741.963318,808.499573 735.841919,803.358337 737.335083,795.693848 
	C738.404175,799.079712 740.121094,800.919678 743.927612,799.020447 
	C748.173889,801.353149 752.193970,804.354675 756.712341,805.882751 
	C766.905884,809.329956 771.838928,816.949280 768.376221,825.509033 
	C767.516113,822.493347 767.009399,819.745911 766.502625,816.998535 
	C765.976807,817.068909 765.450928,817.139221 764.925049,817.209595 
	C764.204956,819.533264 763.484802,821.856873 762.764648,824.180542 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M554.000000,823.965820 
	C554.000000,823.965820 554.399536,823.965088 554.697998,824.416504 
	C555.106873,826.242493 555.217407,827.617065 555.338928,829.129333 
	C560.205688,829.129333 564.605103,829.129333 569.267334,829.379761 
	C572.197693,831.133545 576.031616,826.489868 578.080750,831.252563 
	C576.012329,832.492249 573.997070,834.682129 571.866089,834.801270 
	C564.133606,835.233521 556.362244,834.971619 548.244080,834.971619 
	C548.244080,833.064087 548.244080,831.425049 548.675415,829.310913 
	C550.612366,828.636536 552.117920,828.437195 554.000000,828.187988 
	C554.000000,826.812012 554.000000,825.388916 554.000000,823.965820 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M458.514832,378.356934 
	C466.712982,376.357422 462.286865,369.666992 463.034637,364.427612 
	C467.919586,364.294830 472.769562,364.605530 478.753326,364.988861 
	C474.697113,369.566742 478.586578,378.051453 469.259460,377.875427 
	C465.717499,378.272156 462.336456,378.377319 458.514832,378.356934 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M548.314575,263.589447 
	C542.823792,263.970978 537.299438,264.022949 531.405518,264.031616 
	C533.821533,258.422150 536.607239,252.856003 539.984497,246.107895 
	C543.079346,252.506119 545.680237,257.883026 548.314575,263.589447 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M697.404541,828.882935 
	C703.827515,828.882935 710.250427,828.882935 716.916199,828.882935 
	C716.916199,830.918884 716.916199,832.682556 716.916199,834.856201 
	C708.221619,834.856201 699.515686,834.856201 690.264893,834.856201 
	C690.183411,833.713196 690.083496,832.312195 690.407104,830.396362 
	C693.021851,829.548706 695.213196,829.215820 697.404541,828.882935 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M554.843262,794.045227 
	C552.943665,792.784241 551.044067,791.523254 548.807190,790.200928 
	C548.345764,788.015442 548.221619,785.891357 548.038269,782.754272 
	C557.533142,784.112122 567.089417,779.898132 575.320923,786.916992 
	C572.029785,787.910095 569.016541,788.391113 565.551514,788.958008 
	C561.841003,789.043884 558.582397,789.043884 555.341980,789.043884 
	C555.135437,791.115173 554.989380,792.580200 554.843262,794.045227 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M747.421387,828.899658 
	C752.122559,830.066101 756.720215,830.571289 761.375000,826.955322 
	C763.710999,827.718018 765.325256,828.354553 766.939514,828.991089 
	C760.739502,837.777954 748.530823,838.487000 739.633667,830.091919 
	C742.414978,829.360291 744.918152,829.129944 747.421387,828.899658 
z"/>
<path fill="#F4E99A" opacity="1.000000" stroke="none" 
	d="
M696.841675,796.028259 
	C696.890991,804.697815 696.940369,813.367371 696.676697,822.249390 
	C695.420105,820.948792 694.476562,819.435608 693.533020,817.922485 
	C693.038513,818.114075 692.544006,818.305725 692.049561,818.497314 
	C691.425049,821.321533 690.800598,824.145813 690.176147,826.970032 
	C690.124939,815.965881 690.073730,804.961792 690.342163,793.776123 
	C690.864624,794.864319 690.808472,796.257141 691.338196,797.371460 
	C691.956177,798.671326 693.085999,799.728027 693.993713,800.890198 
	C694.691528,799.675903 695.386047,798.459717 696.089111,797.248474 
	C696.328918,796.835327 696.590210,796.434631 696.841675,796.028259 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M757.571472,789.061584 
	C754.648621,789.027710 751.725769,788.993896 748.378113,788.686584 
	C744.923584,788.565674 741.893860,788.718262 738.864136,788.870789 
	C744.304504,778.969238 762.902039,779.784607 766.234131,789.676758 
	C763.170349,789.636414 760.370911,789.348999 757.571472,789.061584 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M641.030151,834.924866 
	C640.638672,834.493896 640.247192,834.062866 640.000854,832.841675 
	C640.211975,829.607971 640.277893,827.164490 640.558472,824.483093 
	C642.046570,822.611084 643.320007,820.976990 644.593506,819.342896 
	C644.987854,819.569824 645.382263,819.796753 645.776611,820.023682 
	C645.776611,813.293396 645.776611,806.563110 645.776611,800.632874 
	C643.602844,798.890137 641.973450,797.583923 640.283569,795.836426 
	C640.223083,793.812073 640.223083,792.228882 640.223083,790.342407 
	C642.217957,790.342407 643.747681,790.342407 645.322998,790.342407 
	C645.849060,791.969604 646.330872,793.460083 646.812744,794.950623 
	C646.890198,805.908752 646.967712,816.866882 646.692383,828.350769 
	C642.279846,828.882568 640.275635,830.692932 641.030151,834.924866 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M641.477722,835.063110 
	C640.275635,830.692932 642.279846,828.882568 646.748657,828.799072 
	C647.157776,828.721558 647.089172,828.800354 647.301392,829.115234 
	C650.971741,829.617310 654.454163,829.613953 657.880859,830.049072 
	C660.893616,830.431702 663.843994,831.305115 666.821960,831.961731 
	C667.672241,834.823547 666.170288,835.322693 663.730713,835.288757 
	C656.463257,835.187744 649.193909,835.220642 641.477722,835.063110 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M606.083862,829.971924 
	C606.021118,828.247253 605.958313,826.522583 606.258301,824.114258 
	C607.682007,820.877563 605.353577,815.612976 611.704712,817.629517 
	C611.704712,811.675903 611.704712,806.148376 611.704712,800.014954 
	C606.522583,802.680786 607.654602,797.806519 606.095459,796.038452 
	C606.038025,794.642517 605.980591,793.246582 606.286072,791.658325 
	C608.704163,792.965393 610.759277,794.464722 612.814392,795.964111 
	C612.863281,805.348450 612.912170,814.732849 612.645752,824.807922 
	C610.248230,826.989746 608.166077,828.480835 606.083862,829.971924 
z"/>
<path fill="#F4E99A" opacity="1.000000" stroke="none" 
	d="
M606.015198,796.497986 
	C607.654602,797.806519 606.522583,802.680786 611.704712,800.014954 
	C611.704712,806.148376 611.704712,811.675903 611.704712,817.629517 
	C605.353577,815.612976 607.682007,820.877563 606.355896,823.692871 
	C606.038818,814.955933 605.986877,805.956726 606.015198,796.497986 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M640.343872,824.721008 
	C640.277893,827.164490 640.211975,829.607971 640.073181,832.499512 
	C640.000305,816.499023 640.000305,800.050354 640.000305,783.287354 
	C642.070312,783.287354 644.147827,783.287354 647.003296,783.287354 
	C647.003296,786.842712 647.003296,790.448730 646.908020,794.502686 
	C646.330872,793.460083 645.849060,791.969604 645.322998,790.342407 
	C643.747681,790.342407 642.217957,790.342407 640.223083,790.342407 
	C640.223083,792.228882 640.223083,793.812073 640.234619,796.276917 
	C640.278687,806.346069 640.311279,815.533508 640.343872,824.721008 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M597.565552,735.042114 
	C598.028564,735.299561 598.034363,735.535278 598.058105,735.948120 
	C583.684692,736.228027 569.308350,736.330444 554.491211,736.279297 
	C555.572021,735.757751 557.091553,735.080627 558.615540,735.070435 
	C571.441040,734.984741 584.267334,735.021179 597.565552,735.042114 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M578.397827,831.097534 
	C576.031616,826.489868 572.197693,831.133545 569.440552,829.266235 
	C569.351013,828.902344 569.764099,828.878540 570.015198,828.856934 
	C570.498779,828.603760 570.731262,828.372253 571.237488,827.950073 
	C572.889465,825.850037 574.267578,823.940735 575.645752,822.031372 
	C577.684631,820.713257 579.723450,819.395142 582.186157,818.121826 
	C584.373169,823.358582 581.449829,827.121826 578.397827,831.097534 
M581.480286,821.586304 
	C581.480286,821.586304 581.415588,821.476074 581.480286,821.586304 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M747.218079,828.595764 
	C744.918152,829.129944 742.414978,829.360291 739.552124,829.764160 
	C737.814453,827.160400 736.436340,824.383057 734.706482,820.896851 
	C738.304932,820.896851 740.423462,820.896851 741.609131,820.896851 
	C743.649536,823.688171 745.332153,825.990051 747.218079,828.595764 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M738.555115,789.072144 
	C741.893860,788.718262 744.923584,788.565674 747.993958,788.712097 
	C746.614197,790.843445 745.193848,792.675781 743.290283,794.906921 
	C743.021484,795.955505 743.235840,796.605225 743.450195,797.255005 
	C743.631104,797.684448 743.812012,798.113892 743.960266,798.781860 
	C740.121094,800.919678 738.404175,799.079712 737.405945,795.276917 
	C737.521912,793.118103 737.884033,791.195801 738.555115,789.072144 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M566.003357,788.872070 
	C569.016541,788.391113 572.029785,787.910095 575.385132,787.257874 
	C576.730408,788.528564 577.733521,789.970398 578.731567,791.745850 
	C578.300171,793.560608 577.873840,795.041748 577.318848,796.969910 
	C575.821594,796.302734 574.491882,795.710205 572.594971,794.853333 
	C570.019592,792.683350 568.011475,790.777710 566.003357,788.872070 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M757.775513,789.365479 
	C760.370911,789.348999 763.170349,789.636414 766.363037,789.990356 
	C767.672546,792.038879 768.588806,794.020874 769.792847,796.625488 
	C766.807007,796.625488 764.760376,796.625488 762.877808,796.625488 
	C761.265686,794.336060 759.622620,792.002686 757.775513,789.365479 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M690.089966,827.345215 
	C690.800598,824.145813 691.425049,821.321533 692.049561,818.497314 
	C692.544006,818.305725 693.038513,818.114075 693.533020,817.922485 
	C694.476562,819.435608 695.420105,820.948792 696.604492,822.710571 
	C696.952759,824.660767 697.060059,826.362366 697.286011,828.473511 
	C695.213196,829.215820 693.021851,829.548706 690.490051,829.952148 
	C690.101013,829.255249 690.052368,828.487854 690.089966,827.345215 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M696.927612,795.614136 
	C696.590210,796.434631 696.328918,796.835327 696.089111,797.248474 
	C695.386047,798.459717 694.691528,799.675903 693.993713,800.890198 
	C693.085999,799.728027 691.956177,798.671326 691.338196,797.371460 
	C690.808472,796.257141 690.864624,794.864319 690.417847,793.315186 
	C690.121765,792.272827 690.069641,791.509888 690.424622,790.536255 
	C692.841125,790.543457 694.850586,790.761414 696.859985,790.979309 
	C696.911133,792.386230 696.962341,793.793091 696.927612,795.614136 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M696.944824,790.538330 
	C694.850586,790.761414 692.841125,790.543457 690.493530,790.157593 
	C690.155396,787.867004 690.155396,785.744385 690.155396,783.296753 
	C692.315796,783.296753 694.353088,783.296753 697.029602,783.296753 
	C697.029602,785.584229 697.029602,787.840759 696.944824,790.538330 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M605.987732,830.417480 
	C608.166077,828.480835 610.248230,826.989746 612.581787,825.276123 
	C612.833191,828.302124 612.833191,831.550720 612.833191,835.015198 
	C610.495789,835.015198 608.568787,835.015198 606.172241,835.015198 
	C606.081848,833.678284 605.986755,832.270630 605.987732,830.417480 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M497.890564,395.676758 
	C482.066498,395.900055 465.850189,395.952301 449.143158,395.910736 
	C459.444519,395.665619 470.236298,395.474182 481.028931,395.379730 
	C486.517944,395.331665 492.008514,395.458130 497.890564,395.676758 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M766.967041,828.965088 
	C765.325256,828.354553 763.710999,827.718018 761.739624,826.915894 
	C761.382568,826.750244 761.510010,826.520569 761.886047,826.149414 
	C762.471252,825.283081 762.680359,824.787903 762.889465,824.292786 
	C762.889465,824.292786 762.829285,824.233093 762.796997,824.206787 
	C763.484802,821.856873 764.204956,819.533264 764.925049,817.209595 
	C765.450928,817.139221 765.976807,817.068909 766.502625,816.998535 
	C767.009399,819.745911 767.516113,822.493347 768.033936,825.673828 
	C767.995178,826.600708 767.945312,827.094482 767.484009,827.926636 
	C766.928955,828.449219 766.887329,828.654053 766.947693,828.879639 
	C766.947693,828.879639 766.994507,828.939087 766.967041,828.965088 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M554.003845,823.594604 
	C554.000000,825.388916 554.000000,826.812012 554.000000,828.187988 
	C552.117920,828.437195 550.612366,828.636536 548.788696,828.903625 
	C548.404358,828.211487 548.338196,827.451477 548.390747,826.326843 
	C549.270996,823.866089 550.032532,821.770020 551.205444,818.541687 
	C552.316772,820.398438 553.162231,821.810852 554.003845,823.594604 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M606.069763,788.013428 
	C605.986084,786.562622 605.902405,785.111816 605.795898,783.265503 
	C608.171265,783.265503 610.320129,783.265503 613.018494,783.265503 
	C613.018494,785.265991 613.018494,787.172668 612.662964,789.276978 
	C610.228149,788.987488 608.148987,788.500427 606.069763,788.013428 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M606.008179,788.389160 
	C608.148987,788.500427 610.228149,788.987488 612.573181,789.715698 
	C612.884216,791.678955 612.929443,793.401123 612.894531,795.543701 
	C610.759277,794.464722 608.704163,792.965393 606.375244,791.246826 
	C606.049866,790.273315 605.998291,789.519104 606.008179,788.389160 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M542.487549,736.017822 
	C542.487549,736.017822 542.493591,736.112000 542.539795,736.115723 
	C540.141052,736.261780 537.696228,736.404175 533.904907,736.624939 
	C538.179688,734.754272 541.220703,733.423584 544.633606,732.053589 
	C544.485046,732.589111 543.964600,733.163879 542.900757,733.896545 
	C542.400757,734.708923 542.444153,735.363403 542.487549,736.017822 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M666.905396,831.522339 
	C663.843994,831.305115 660.893616,830.431702 657.880859,830.049072 
	C654.454163,829.613953 650.971741,829.617310 647.273438,829.071045 
	C652.615173,828.731140 658.202271,828.627930 663.774109,828.873535 
	C664.876038,828.922119 665.918762,830.311707 666.905396,831.522339 
z"/>
<path fill="#F4E99A" opacity="1.000000" stroke="none" 
	d="
M732.002747,584.598145 
	C728.434326,583.175720 724.767334,581.419556 721.069763,579.292236 
	C724.660889,580.702148 728.282471,582.483276 732.002747,584.598145 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M620.541504,558.842224 
	C620.997253,559.140564 620.999939,559.310547 621.000061,559.735352 
	C614.515015,559.845520 608.032410,559.700806 601.258057,559.250977 
	C607.340332,558.868652 613.714355,558.791260 620.541504,558.842224 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M613.604797,492.015076 
	C614.014648,492.026855 614.011536,492.455872 614.008179,492.670349 
	C610.557983,492.574982 607.111145,492.265137 603.476929,491.554352 
	C606.591370,491.436737 609.893188,491.720032 613.604797,492.015076 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M485.537872,341.151367 
	C487.182434,342.130432 489.008820,343.362671 490.784485,344.961731 
	C489.062408,344.020569 487.391052,342.712555 485.537872,341.151367 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M686.982422,611.355835 
	C689.170959,611.442017 692.425476,609.723267 692.992920,613.974487 
	C690.867981,613.329468 689.021973,612.485474 686.982422,611.355835 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M631.612793,291.976624 
	C630.498230,293.244324 629.067932,294.603119 627.261597,295.941528 
	C628.356079,294.636658 629.826599,293.352173 631.612793,291.976624 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M512.867798,395.696167 
	C509.721649,395.921936 506.200195,395.977020 502.200867,395.941315 
	C505.312775,395.742188 508.902618,395.633820 512.867798,395.696167 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M564.138794,294.525665 
	C560.365784,294.810120 561.112793,291.982880 561.041382,289.244598 
	C562.071411,290.608948 563.084900,292.388672 564.138794,294.525665 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M639.557068,440.083191 
	C640.002686,440.173401 640.008362,440.568604 640.010254,440.765747 
	C636.890381,440.865112 633.768494,440.767303 630.340820,440.409607 
	C633.060425,440.097504 636.085938,440.045258 639.557068,440.083191 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M674.390869,556.052307 
	C674.576721,559.297913 672.797668,559.330750 670.076050,558.023804 
	C671.147888,557.277283 672.575867,556.671448 674.390869,556.052307 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M636.593750,287.941742 
	C635.835938,288.899597 634.766113,289.939575 633.322266,290.973663 
	C634.059448,289.986450 635.170593,289.005157 636.593750,287.941742 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M658.359863,300.002563 
	C658.183533,298.913879 658.291992,297.718964 658.662415,296.276581 
	C658.831116,297.318207 658.737854,298.607269 658.359863,300.002563 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M520.749390,283.214813 
	C520.564697,284.291290 520.128540,285.578308 519.332397,286.923889 
	C519.481018,285.796753 519.989441,284.611084 520.749390,283.214813 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M689.585754,736.302246 
	C690.564148,736.094543 691.853455,736.009766 693.470703,735.929810 
	C692.497986,736.098145 691.197327,736.261658 689.585754,736.302246 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M640.994507,286.000916 
	C640.893188,286.322784 640.706482,286.583557 640.216064,286.891571 
	C639.173401,287.305756 638.349243,287.611603 637.192566,287.908752 
	C637.396179,287.325134 637.932312,286.750244 639.083252,286.089172 
	C640.130188,286.002258 640.562317,286.001587 640.994507,286.000916 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M658.997559,292.376251 
	C659.409607,291.773224 659.824097,291.562073 660.369080,291.616577 
	C660.209290,292.874176 659.919006,293.866119 659.336792,294.987732 
	C659.029907,294.334290 659.015015,293.551208 658.997559,292.376251 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M546.354248,736.294739 
	C546.623962,736.025940 547.200745,735.893005 547.860901,735.944824 
	C547.516602,736.229919 547.088989,736.330261 546.354248,736.294739 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M421.995972,292.296234 
	C422.257935,292.608490 422.490448,293.268250 422.483185,293.966248 
	C422.151123,293.550903 422.058807,293.097321 421.995972,292.296234 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M447.031494,395.654236 
	C446.101227,395.877502 444.865753,395.940796 443.175476,395.904724 
	C444.055878,395.701660 445.391052,395.597961 447.031494,395.654236 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M643.724182,371.549988 
	C643.572021,371.039795 643.547668,370.182800 643.751770,369.161133 
	C643.937439,369.732025 643.894714,370.467621 643.724182,371.549988 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M433.440125,345.731384 
	C433.816956,346.104065 434.224182,346.812408 434.397522,347.746216 
	C433.932648,347.336823 433.701599,346.701935 433.440125,345.731384 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M569.387085,363.385376 
	C569.501770,363.042419 569.900574,362.572937 570.625488,362.041016 
	C570.524841,362.405334 570.097961,362.832062 569.387085,363.385376 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M550.712158,729.045166 
	C550.533936,729.327393 550.067200,729.621643 549.315552,729.963440 
	C549.494873,729.693054 549.959229,729.375122 550.712158,729.045166 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M640.949585,285.665894 
	C640.562317,286.001587 640.130188,286.002258 639.373901,286.008362 
	C639.425659,285.620087 639.801453,285.226379 640.451477,284.755829 
	C640.785339,284.896271 640.845032,285.113556 640.949585,285.665894 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M743.508545,796.889404 
	C743.235840,796.605225 743.021484,795.955505 743.108765,795.183838 
	C743.462585,795.549255 743.514771,796.036499 743.508545,796.889404 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M518.738220,287.146301 
	C518.794617,287.535431 518.611023,288.102936 518.161926,288.821472 
	C518.097046,288.423248 518.297668,287.873962 518.738220,287.146301 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M521.840698,281.333405 
	C521.859070,281.666656 521.666138,282.181976 521.237915,282.848358 
	C521.211609,282.504761 521.420532,282.010101 521.840698,281.333405 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M612.277954,378.256714 
	C612.527039,377.981903 613.109802,377.811584 613.833252,377.886047 
	C613.519836,378.207642 613.065796,378.284424 612.277954,378.256714 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M665.842468,263.768372 
	C665.861572,263.730469 665.969177,263.914490 665.969177,263.914490 
	C665.969177,263.914490 665.850098,263.711670 665.794312,263.757812 
	C665.738586,263.803986 665.823364,263.806274 665.842468,263.768372 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M522.617554,279.711304 
	C522.753906,279.907654 522.639038,280.270294 522.309204,280.825256 
	C522.184998,280.637573 522.275635,280.257568 522.617554,279.711304 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M767.068970,828.779419 
	C766.887329,828.654053 766.928955,828.449219 767.201782,828.169800 
	C767.346863,828.290771 767.299988,828.492310 767.068970,828.779419 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M762.550537,824.541504 
	C762.680359,824.787903 762.471252,825.283081 762.008362,826.101562 
	C761.906982,825.880066 762.059326,825.335144 762.550537,824.541504 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M641.120117,284.173889 
	C641.249146,284.316345 641.144775,284.552734 640.856567,284.756226 
	C640.744080,284.571472 640.815369,284.419678 641.120117,284.173889 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M548.223389,801.264038 
	C548.470886,801.194885 548.634216,801.331299 548.740479,801.502930 
	C548.778503,801.564453 548.678650,801.711304 548.480164,801.873535 
	C548.318604,801.927795 548.116638,801.483521 548.223389,801.264038 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M560.904785,287.731415 
	C560.488892,287.864532 560.349548,287.633759 560.542114,287.176819 
	C560.749756,287.199371 560.852234,287.338348 560.904785,287.731415 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M442.094421,285.647858 
	C441.881012,285.730072 441.548859,285.533722 441.068359,285.081207 
	C441.271881,285.006470 441.623779,285.187866 442.094421,285.647858 
z"/>
<path fill="#BD994A" opacity="1.000000" stroke="none" 
	d="
M548.214600,816.286133 
	C548.427124,816.317871 548.538757,816.577698 548.489258,816.901123 
	C548.328186,816.964722 548.113647,816.514282 548.214600,816.286133 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M444.045776,287.677734 
	C443.845917,287.702087 443.553131,287.438995 443.183777,286.883057 
	C443.389069,286.856873 443.670959,287.123535 444.045776,287.677734 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M669.256531,493.914612 
	C669.294312,493.758270 669.566956,493.699707 669.918213,493.798340 
	C669.828308,493.974457 669.659851,493.993378 669.256531,493.914612 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M551.894531,601.053955 
	C551.999573,600.988403 551.835938,601.315796 551.881104,601.394775 
	C551.926331,601.473816 551.789429,601.119507 551.894531,601.053955 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M709.019409,446.682007 
	C708.765808,446.885345 708.477600,446.728668 708.116577,446.257996 
	C708.303528,446.169006 708.617249,446.295013 709.019409,446.682007 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M707.842041,445.912170 
	C707.911743,446.026337 707.508484,445.861023 707.401489,445.845886 
	C707.443298,445.668549 707.602600,445.657654 707.842041,445.912170 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M700.585327,670.834900 
	C700.677551,671.055420 700.508850,671.380493 700.078735,671.813843 
	C699.986389,671.594604 700.155396,671.267029 700.585327,670.834900 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M699.617981,671.939331 
	C699.687073,672.166382 699.451538,672.509949 698.932373,673.011353 
	C698.870239,672.798157 699.091797,672.427063 699.617981,671.939331 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M602.664551,685.908813 
	C602.750977,686.123230 602.570801,686.430054 602.147949,686.858032 
	C602.069519,686.653259 602.233765,686.327209 602.664551,685.908813 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M599.980103,689.167786 
	C600.255676,689.241150 600.341187,689.459167 600.426636,689.677124 
	C600.307861,689.720581 600.189026,689.764038 599.874390,689.792114 
	C599.678589,689.776794 599.790039,689.312378 599.980103,689.167786 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M599.562256,689.790161 
	C599.662720,690.030701 599.485840,690.365967 599.030640,690.796997 
	C598.929810,690.556824 599.107361,690.220886 599.562256,689.790161 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M598.611511,690.854736 
	C598.711792,691.081970 598.542480,691.401978 598.123047,691.843872 
	C598.029297,691.626404 598.185608,691.287048 598.611511,690.854736 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M597.675415,691.985229 
	C597.738220,692.196167 597.510498,692.515991 597.023804,692.994507 
	C596.971497,692.800232 597.178223,692.447205 597.675415,691.985229 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M632.710449,291.004944 
	C632.770935,291.219025 632.567261,291.492157 632.118042,291.853333 
	C632.063721,291.648895 632.255005,291.356445 632.710449,291.004944 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M548.716797,730.022217 
	C548.744873,730.261536 548.485779,730.502686 547.953003,730.771301 
	C547.929443,730.540527 548.179565,730.282288 548.716797,730.022217 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M547.508789,730.656677 
	C547.574951,730.861206 547.417297,731.105347 547.132202,731.202026 
	C547.004883,731.054443 547.284973,730.696289 547.508789,730.656677 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M502.932861,355.356049 
	C503.142944,355.281097 503.468140,355.484375 503.932617,355.947266 
	C503.730560,356.015991 503.389282,355.825134 502.932861,355.356049 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M589.325806,347.094604 
	C589.268250,346.881073 589.483521,346.580505 589.967834,346.187042 
	C590.024109,346.398621 589.811401,346.703064 589.325806,347.094604 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M590.383423,346.139343 
	C590.354004,345.915466 590.637390,345.589050 591.243347,345.176514 
	C591.276001,345.405884 590.986023,345.721344 590.383423,346.139343 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M490.781769,345.479431 
	C491.003479,345.371490 491.347382,345.521729 491.812439,345.935364 
	C491.590363,346.045044 491.247162,345.891357 490.781769,345.479431 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M626.672485,295.939819 
	C626.736023,296.165375 626.529236,296.452148 626.061157,296.815552 
	C626.000610,296.595154 626.201355,296.298096 626.672485,295.939819 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M695.400024,721.952393 
	C682.650940,722.008362 670.300415,722.012268 657.064697,722.008301 
	C654.790405,721.991577 653.401306,721.982666 652.012207,721.973816 
	C648.607971,716.942261 649.921814,712.865906 655.896484,709.887207 
	C656.486145,709.593323 657.052795,709.249329 657.613342,708.901489 
	C665.511841,704.000916 673.070190,700.350037 683.445007,701.575989 
	C697.742310,703.265381 712.388794,702.035645 726.885315,701.970947 
	C729.909485,701.957458 732.313293,702.327148 732.004150,706.603027 
	C722.882507,715.064880 712.034302,719.188660 700.174377,721.027161 
	C698.705872,721.254700 697.256653,721.606262 695.400024,721.952393 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M705.391479,687.950562 
	C705.529785,686.811707 706.124451,685.698486 706.856079,684.291382 
	C709.812683,681.114990 712.632263,678.232422 716.008667,675.455078 
	C726.612732,675.724060 736.663208,675.794495 746.704346,676.136902 
	C748.517517,676.198669 750.292603,677.377625 752.085266,678.040833 
	C750.278992,682.265747 748.472778,686.490601 746.370361,690.893005 
	C744.798584,690.379211 743.545044,689.134705 742.243835,689.082642 
	C730.116455,688.597107 717.980957,688.316284 705.391479,687.950562 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M651.542358,721.983276 
	C653.401306,721.982666 654.790405,721.991577 656.596924,722.014038 
	C656.089233,723.745911 655.061584,725.418518 654.269470,727.196045 
	C653.560120,728.787903 653.110291,730.495361 652.545959,732.151794 
	C652.874207,732.732483 653.202393,733.313171 653.530640,733.893799 
	C640.337891,734.267029 627.145081,734.640198 613.485962,735.011597 
	C613.019531,733.210632 613.019531,731.411499 613.019531,728.804016 
	C613.054871,728.773071 614.246765,728.249695 614.442749,727.474548 
	C616.069214,721.042419 621.037903,721.927124 625.783203,721.978271 
	C634.212036,722.069092 642.642578,721.996765 651.542358,721.983276 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M542.649658,735.706665 
	C542.444153,735.363403 542.400757,734.708923 542.636230,734.027466 
	C542.880615,734.465515 542.846191,734.930542 542.649658,735.706665 
z"/>
<path fill="#D8B45B" opacity="1.000000" stroke="none" 
	d="
M685.024963,454.886017 
	C693.462952,457.501587 701.900940,460.117188 710.673096,462.856873 
	C714.599304,466.110138 718.191406,469.239258 721.943298,472.668396 
	C710.061401,476.437988 697.822693,472.836792 685.709534,473.972168 
	C681.282532,474.387115 676.826599,474.503571 672.381775,474.710144 
	C671.582336,474.747284 670.668335,474.774628 669.983459,474.442810 
	C662.737183,470.931519 655.399414,467.576447 648.361877,463.681458 
	C645.032471,461.838745 642.924805,458.758850 645.977051,454.404114 
	C648.382690,454.045898 650.744690,454.033569 654.019165,454.016846 
	C664.962830,454.303650 674.993896,454.594818 685.024963,454.886017 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M655.531372,478.997009 
	C654.557983,482.614471 653.115906,486.227722 651.173889,491.093781 
	C640.478516,493.219940 628.158691,491.481201 615.479492,492.005768 
	C615.894592,490.935211 616.776978,489.869202 617.711914,488.739746 
	C615.419373,482.496124 615.450745,481.777771 622.298096,480.204010 
	C627.226379,479.071289 632.473694,479.192352 637.587341,479.036041 
	C643.407471,478.858124 649.237122,478.993561 655.531372,478.997009 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M699.987854,499.025024 
	C697.086304,495.885864 694.184753,492.746704 691.140564,489.307648 
	C701.252136,488.697968 711.507812,488.426971 721.759827,488.057068 
	C725.285156,487.929932 728.801575,487.555908 732.676270,487.145569 
	C734.654114,490.744354 736.277588,494.492279 737.517517,498.629791 
	C734.186523,499.347870 731.233459,499.997711 728.292786,499.945587 
	C718.855347,499.778381 709.422485,499.351654 699.987854,499.025024 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M684.900146,485.672302 
	C684.072876,485.530487 683.091431,485.106903 682.058350,484.334290 
	C682.919739,484.453644 683.832825,484.922058 684.900146,485.672302 
z"/>
<path fill="#EFDE89" opacity="1.000000" stroke="none" 
	d="
M721.998230,659.997559 
	C722.436157,653.338318 722.629944,646.649475 723.431335,640.034302 
	C723.755127,637.361694 725.060120,634.627686 726.539490,632.319519 
	C728.540466,629.197388 731.616394,631.948730 733.905029,631.560730 
	C740.419434,630.456299 746.197266,632.590393 752.604248,633.955688 
	C753.997620,641.699890 755.000122,649.394958 755.583984,657.490845 
	C746.357605,657.674377 737.547607,657.262695 728.744141,657.373291 
	C726.484802,657.401672 724.246277,659.078186 721.998230,659.997559 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M721.850586,660.336914 
	C724.246277,659.078186 726.484802,657.401672 728.744141,657.373291 
	C737.547607,657.262695 746.357605,657.674377 755.578979,657.948181 
	C754.866638,664.511475 753.740662,671.018372 752.349976,677.783081 
	C750.292603,677.377625 748.517517,676.198669 746.704346,676.136902 
	C736.663208,675.794495 726.612732,675.724060 716.273376,675.255310 
	C717.888489,670.192200 719.795654,665.434265 721.850586,660.336914 
z"/>
<path fill="#F3E593" opacity="1.000000" stroke="none" 
	d="
M648.991699,572.987793 
	C652.357605,572.994690 655.723450,573.001587 659.548340,573.017578 
	C658.978760,575.228149 657.517700,577.335754 657.014465,579.651428 
	C656.054382,584.069214 655.640320,588.605591 654.998169,593.554932 
	C647.643616,594.011353 640.290405,594.005615 632.468628,593.555908 
	C633.340149,586.414124 634.680237,579.716309 636.481445,573.016479 
	C640.958923,573.005615 644.975281,572.996704 648.991699,572.987793 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M700.028809,499.375549 
	C709.422485,499.351654 718.855347,499.778381 728.292786,499.945587 
	C731.233459,499.997711 734.186523,499.347870 737.553955,499.001923 
	C738.647278,503.030609 739.320679,507.076752 739.992188,511.558899 
	C730.259827,512.283936 720.527954,512.768799 710.800415,512.691650 
	C708.927917,512.676819 706.558716,510.839050 705.324036,509.171753 
	C703.197815,506.300720 701.783081,502.902832 700.028809,499.375549 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M509.080780,341.796661 
	C509.080780,341.796661 509.088745,341.372040 508.999512,341.179199 
	C509.873077,339.022308 510.823425,337.052032 511.800690,335.095184 
	C518.516541,321.647675 525.238098,308.202972 532.009277,294.399902 
	C532.060730,294.042419 532.012939,294.032806 532.304871,293.852539 
	C533.409790,291.788025 534.222778,289.903809 535.035828,288.019623 
	C535.035828,288.019623 534.994446,288.008545 535.284912,287.840088 
	C536.067505,286.456451 536.559631,285.241302 537.051758,284.026154 
	C537.051758,284.026154 536.992737,284.021393 537.259705,283.857056 
	C537.987915,282.792511 538.449158,281.892334 538.910400,280.992126 
	C541.703979,285.872955 544.497559,290.753754 547.586975,295.815186 
	C554.037476,308.016541 560.184937,320.040955 566.352112,332.055267 
	C567.782471,334.841827 569.279846,337.593994 570.901123,340.678467 
	C570.743408,341.425323 570.430908,341.855652 569.755615,342.627380 
	C569.189270,343.282471 568.985718,343.596161 568.782227,343.909882 
	C568.782227,343.909882 568.900208,343.910889 568.587158,343.968567 
	C567.534302,344.763123 566.794495,345.500031 566.054626,346.236908 
	C558.764221,352.189697 551.473816,358.142517 543.993286,364.357605 
	C543.803101,364.619812 543.839111,364.634277 543.556274,364.728760 
	C543.045044,365.115784 542.816650,365.408386 542.588196,365.700989 
	C542.588196,365.700989 542.732849,365.653442 542.477905,365.661804 
	C542.222900,365.670135 542.003723,366.004974 542.003723,366.004944 
	C540.919800,365.746338 539.835815,365.487701 538.425659,365.102448 
	C532.008972,359.990631 525.918457,355.005463 519.393372,349.722961 
	C518.695618,349.180176 518.432373,348.934662 518.169189,348.689178 
	C518.169189,348.689178 518.146423,348.864777 518.120483,348.579285 
	C517.759155,348.121490 517.423889,347.949188 517.088562,347.776886 
	C517.088562,347.776886 517.075439,347.925079 517.039307,347.660706 
	C516.676941,347.238434 516.350769,347.080566 516.024536,346.922699 
	C516.024536,346.922699 516.008301,346.991180 515.923035,346.701477 
	C515.325562,346.116516 514.813354,345.821289 514.301147,345.526062 
	C514.301147,345.526062 514.260010,345.694031 514.190430,345.390717 
	C513.462280,344.650665 512.803711,344.213959 512.145203,343.777222 
	C512.145203,343.777222 512.091980,343.869659 511.999390,343.564575 
	C510.964813,342.771881 510.022797,342.284271 509.080780,341.796661 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M636.020325,573.018433 
	C634.680237,579.716309 633.340149,586.414124 632.000000,593.556030 
	C630.595642,594.009399 629.191284,594.018799 627.363831,594.054321 
	C622.934265,594.062317 618.927856,594.044250 614.458496,593.559204 
	C614.328613,586.392334 614.661743,579.692505 614.994873,572.992676 
	C617.358521,572.987000 619.722168,572.981323 622.994751,572.984131 
	C627.942566,573.001282 631.981445,573.009888 636.020325,573.018433 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M614.528442,572.990601 
	C614.661743,579.692505 614.328613,586.392334 613.995178,593.563904 
	C608.827698,593.965210 603.659607,593.926941 598.495178,593.768188 
	C598.042786,593.754333 597.266724,592.977844 597.229492,592.508972 
	C596.879639,588.099487 596.211121,583.648071 596.522400,579.271973 
	C596.846558,574.715149 599.478943,572.211609 604.608521,572.915771 
	C607.704651,573.340820 610.907227,572.990540 614.528442,572.990601 
z"/>
<path fill="#E3C974" opacity="1.000000" stroke="none" 
	d="
M636.481445,573.016541 
	C631.981445,573.009888 627.942566,573.001282 623.437256,572.981018 
	C622.976868,572.803528 622.915771,572.585266 622.998535,572.479492 
	C626.619019,567.851440 624.789551,564.122681 621.041016,560.351074 
	C629.272827,560.002197 637.535950,559.988464 645.799011,560.006348 
	C651.520935,560.018738 653.304565,562.900696 650.910706,568.205750 
	C650.300964,569.557068 649.688599,570.907166 649.034546,572.622803 
	C644.975281,572.996704 640.958923,573.005615 636.481445,573.016541 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M534.673584,288.071655 
	C534.222778,289.903809 533.409790,291.788025 532.319580,293.871765 
	C532.798706,292.088776 533.554993,290.106232 534.673584,288.071655 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M536.687256,284.081726 
	C536.559631,285.241302 536.067505,286.456451 535.299927,287.855835 
	C535.457275,286.739136 535.890076,285.438232 536.687256,284.081726 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M538.573120,281.063293 
	C538.449158,281.892334 537.987915,282.792511 537.281250,283.877380 
	C537.435791,283.086182 537.835815,282.110321 538.573120,281.063293 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M684.974609,454.535919 
	C674.993896,454.594818 664.962830,454.303650 654.462402,454.010925 
	C653.661011,449.939514 653.329102,445.869720 652.997070,441.409668 
	C661.759888,441.349365 670.530518,441.561218 679.276917,442.139465 
	C680.554688,442.223938 682.910095,444.680725 682.751709,444.982819 
	C680.745789,448.807098 684.040833,451.197815 684.974609,454.535919 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M566.308594,346.457581 
	C566.794495,345.500031 567.534302,344.763123 568.567505,343.912964 
	C568.094727,344.759155 567.328613,345.718689 566.308594,346.457581 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M569.052551,343.816986 
	C568.985718,343.596161 569.189270,343.282471 569.668823,342.883118 
	C569.737549,343.106354 569.530212,343.415222 569.052551,343.816986 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M569.764099,828.878540 
	C569.764099,828.878540 569.351013,828.902344 569.177734,829.015869 
	C564.605103,829.129333 560.205688,829.129333 555.338928,829.129333 
	C555.217407,827.617065 555.106873,826.242493 554.897095,824.431702 
	C554.797791,819.875000 554.797791,815.754456 554.797791,811.687012 
	C569.425415,809.515686 575.539612,811.323364 575.663940,821.642700 
	C574.267578,823.940735 572.889465,825.850037 570.973755,827.936035 
	C570.212158,828.367920 569.988159,828.623230 569.764099,828.878540 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M565.551514,788.958008 
	C568.011475,790.777710 570.019592,792.683350 572.207642,794.850525 
	C572.656006,801.863098 570.152405,804.670593 563.633118,804.836365 
	C560.996216,804.903503 558.356201,804.847473 554.919128,804.847473 
	C554.919128,801.430054 554.919128,798.195496 554.881226,794.503113 
	C554.989380,792.580200 555.135437,791.115173 555.341980,789.043884 
	C558.582397,789.043884 561.841003,789.043884 565.551514,788.958008 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M542.893555,365.677979 
	C542.816650,365.408386 543.045044,365.115784 543.550476,364.746674 
	C543.617920,364.998413 543.408447,365.326691 542.893555,365.677979 
z"/>
<path fill="#EAD580" opacity="1.000000" stroke="none" 
	d="
M542.126221,366.160095 
	C542.003723,366.004974 542.222900,365.670135 542.415344,365.618347 
	C542.488098,365.816101 542.368408,366.065643 542.126221,366.160095 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M512.146301,344.149048 
	C512.803711,344.213959 513.462280,344.650665 514.266785,345.350189 
	C513.657654,345.248932 512.902527,344.884888 512.146301,344.149048 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M509.053955,342.165100 
	C510.022797,342.284271 510.964813,342.771881 512.052063,343.556061 
	C511.398865,343.904083 510.600494,343.955475 509.402863,344.009369 
	C509.011475,343.519104 509.019318,343.026306 509.053955,342.165100 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M514.336853,345.855225 
	C514.813354,345.821289 515.325562,346.116516 515.955078,346.686890 
	C515.505737,346.702820 514.939148,346.443604 514.336853,345.855225 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M518.228394,348.968262 
	C518.432373,348.934662 518.695618,349.180176 519.047485,349.694397 
	C518.853271,349.724518 518.570496,349.485931 518.228394,348.968262 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M517.196960,348.047607 
	C517.423889,347.949188 517.759155,348.121490 518.206909,348.560181 
	C517.981323,348.657166 517.643311,348.487762 517.196960,348.047607 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M516.154907,347.159973 
	C516.350769,347.080566 516.676941,347.238434 517.113037,347.651001 
	C516.910339,347.736206 516.597778,347.566742 516.154907,347.159973 
z"/>
<path fill="#F4E99A" opacity="1.000000" stroke="none" 
	d="
M640.558472,824.483093 
	C640.311279,815.533508 640.278687,806.346069 640.295105,796.718140 
	C641.973450,797.583923 643.602844,798.890137 645.776611,800.632874 
	C645.776611,806.563110 645.776611,813.293396 645.776611,820.023682 
	C645.382263,819.796753 644.987854,819.569824 644.593506,819.342896 
	C643.320007,820.976990 642.046570,822.611084 640.558472,824.483093 
z"/>
<path fill="#DBBB64" opacity="1.000000" stroke="none" 
	d="
M570.015198,828.856934 
	C569.988159,828.623230 570.212158,828.367920 570.699951,828.126709 
	C570.731262,828.372253 570.498779,828.603760 570.015198,828.856934 
z"/>
<path fill="#F0E496" opacity="1.000000" stroke="none" 
	d="
M581.447937,821.531189 
	C581.415588,821.476074 581.480286,821.586304 581.447937,821.531189 
z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
